import React from 'react';
import { FormattedMessage } from 'react-intl';

const UserRow = () => (
  <thead>
    <tr>
      <th style={{ width: '50%' }}>
        <FormattedMessage id="OrganizationUsers.username" />
      </th>
      <th style={{ width: '25%' }}>
        <FormattedMessage id="OrganizationUsers.firstName" />
      </th>
      <th style={{ width: '25%' }}>
        <FormattedMessage id="OrganizationUsers.lastName" />
      </th>
    </tr>
  </thead>
);

export default UserRow;
