import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const Endpoint = ({ endpoint, redirect, href }) => (
  <td>
    {
      endpoint && endpoint.name ?
        <Button
          color="link"
          className="m-0 px-0 font-weight-bold text-nowrap"
          onClick={redirect}
          href={href}
        >
          {endpoint.name}
        </Button>
        :
        <FormattedMessage id="GraspInventory.table.notAssigned" />
    }
  </td>
);

export default Endpoint;
