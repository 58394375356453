const initialState = {
  isResetting: false,
  resetFailed: false,
  resetSuccess: false,
  resetError: [],
};

const passwordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_PASSWORD_RESETTING':
      return {
          ...state,
          isResetting: true,
          resetFailed: false,
          resetSuccess: false,
        };

    case 'RESET_PASSWORD_SUCCESS':
      return {
          ...state,
          resetSuccess: true,
          isResetting: false,
        };

    case 'RESET_PASSWORD_FAILED':
      return {
          ...state,
          resetError: action.errorMessage,
          resetFailed: true,
          isResetting: false,
        };

    default:
      return state;
  }
};

export default passwordResetReducer;
