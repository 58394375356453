import React from 'react';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import OrganizationImage from '../tableComponents/OrganizationImg';

const Organization = ({ organization, redirect, href }) => (
  <td className="text-center">
    {
      organization && organization.id ?
        <a
          className="d-flex align-items-center"
          onClick={redirect}
          href={href}
        >
          <OrganizationImage
            height={40}
            alt={organization.name}
            src={organization.logo_url}
          />

          <p className="mb-0 py-2 font-weight-bold">{organization.name}</p>
        </a>
        :
        <FormattedMessage id="GraspInventory.table.notAssigned" />
    }
  </td>
);

export default Organization;
