import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// COMPONENTS:
import GraspName from '../../../../../components/GraspName';

// ACTIONS:
import { unassignGraspFromEndpointAction } from './reducer';

const UnlinkedGraspsRow = ({
  id,
  name,
  physical_grasp_uuid,
  match,
  unassignGraspFromEndpoint,
}) => {
  const handleAction = () => {
    unassignGraspFromEndpoint(match.params.id, match.params.endpointId, id);
  };

  return (
    <tr>
      <td>
        <GraspName name={name} uuid={physical_grasp_uuid} />
      </td>

      <td className="text-right">
        <Button
          color="primary"
          size="sm"
          onClick={handleAction}
        >
          <FormattedMessage id="OrganizationGrasps.actions.unassign" />
        </Button>
      </td>
    </tr>
  );
};

const mapDispatchToProps = {
  unassignGraspFromEndpoint: unassignGraspFromEndpointAction,
};

export default connect(null, mapDispatchToProps)(withRouter(UnlinkedGraspsRow));
