import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { editEndpointAction } from '../reducer';

// COMPONENTS:
import InputCustom from '../../../../components/forms/InputCustom';
import LoadingButton from '../../../../components/LoadingButton';

const EndpointSettings = ({
  isOpen,
  toggle,
  className,
  name,
  editEndpoint,
  isLoading,
  isSuccess,
  error,
  match,
}) => {
  const { id, endpointId } = match.params;
  const [inputValue, updateInputValue] = useState(name);
  const handleChange = ({ target }) => updateInputValue(target.value);
  const handleEditEndpointName = () => {
    if (inputValue === name) {
      toggle();
    } else {
      editEndpoint(id, endpointId, { name: inputValue });
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="EndpointDetails.settings.title" />
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          <InputCustom
            name="name"
            label={<FormattedMessage id="AddOrganization.name" />}
            handleChange={handleChange}
            value={inputValue}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            updateInputValue(name);
            toggle();
          }}
        >
          <FormattedMessage id="EndpointDetails.settings.cancel" />
        </Button>
        <LoadingButton
          isLoading={isLoading}
          isSucceed={isSuccess}
          isFailed={!!error}
          callback={toggle}
          value="EndpointDetails.settings.save"
          onClick={handleEditEndpointName}
          type="submit"
        />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({
  organization: {
    endpoint: {
      updating: { isLoading, isSuccess, error },
    },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  editEndpoint: editEndpointAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EndpointSettings));
