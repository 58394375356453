import request from '../request';

// HELPER:
const getQueryParams = ({
  page,
  pageSize,
  sortBy,
  filterBy,
  query
  // eslint-disable-next-line
}) =>
  `?page=${page}${pageSize ? `&page_size=${pageSize}` : ''}${
    sortBy ? `&sort=${sortBy}` : ''
  }${filterBy ? `&filter_by=${filterBy}` : ''}${
    query ? `&query=${query}` : ''
  }`;

// ENDPOINTS:
export const addDeviceToEndpoint = (organizationId, endpointId, data) =>
  request({
    url: `/organizations/${organizationId}/endpoints/${endpointId}/grasps`,
    method: 'POST',
    data
  });

export const assignDeviceToOrganization = (organizationId, graspId) =>
  request({
    url: `/organizations/${organizationId}/grasps/${graspId}/add_grasp_to_organization`,
    method: 'PUT'
  });

export const getGraspInventory = params =>
  request({
    url: `/grasps${getQueryParams(params)}`,
    method: 'GET'
  });

export const getOrganizations = params =>
  request({
    url: `/organizations${getQueryParams(params)}`,
    method: 'GET'
  });

export const getOrganizationDetails = id =>
  request({
    url: `/organizations/${id}`,
    method: 'GET'
  });

export const addOrganization = data =>
  request({
    url: '/organizations',
    method: 'POST',
    data
  });

export const createEndpoint = (organizationId, data) =>
  request({
    url: `/organizations/${organizationId}/endpoints`,
    method: 'POST',
    data
  });

export const updateOrganization = (organizationId, data) =>
  request({
    url: `/organizations/${organizationId}`,
    method: 'PATCH',
    data
  });

export const updateEndpoint = (organizationId, endpointId, data) =>
  request({
    url: `/organizations/${organizationId}/endpoints/${endpointId}`,
    method: 'PATCH',
    data
  });

export const getEndpoints = (params, organizationId) =>
  request({
    url: `/organizations/${organizationId}/endpoints${getQueryParams(params)}`,
    method: 'GET'
  });

export const getEndpointDetails = (organizationId, endpointId) =>
  request({
    url: `/organizations/${organizationId}/endpoints/${endpointId}`,
    method: 'GET'
  });

export const getEndpointGrasps = (params, organizationId, endpointId) =>
  request({
    url: `/organizations/${organizationId}/endpoints/${endpointId}/grasps${getQueryParams(
      params
    )}`,
    method: 'GET'
  });

export const unassignGraspFromEndpoint = (
  organizationId,
  endpointId,
  graspId
) =>
  request({
    url: `/organizations/${organizationId}/endpoints/${endpointId}/grasps/${graspId}`,
    method: 'DELETE'
  });

export const removeGraspFromOrganization = (organizationId, graspId) =>
  request({
    url: `/organizations/${organizationId}/grasps/${graspId}`,
    method: 'DELETE'
  });

export const unlinkGraspFromUser = (organizationId, graspId) =>
  request({
    url: `/organizations/${organizationId}/grasps/${graspId}/force_unlink`,
    method: 'POST'
  });

export const assignGraspToEndpoint = (organizationId, endpointId, data) =>
  request({
    url: `/organizations/${organizationId}/endpoints/${endpointId}/grasps`,
    method: 'POST',
    data
  });

export const getOrganizationGrasps = (params, organizationId) =>
  request({
    url: `/organizations/${organizationId}/grasps${getQueryParams(params)}`,
    method: 'GET'
  });

export const getUsersFromOrganization = (params, organizationId) =>
  request({
    url: `/organizations/${organizationId}/users${getQueryParams(params)}`,
    method: 'GET'
  });

export const addGraspToOrganization = (organizationId, data) =>
  request({
    url: `/organizations/${organizationId}/grasps`,
    method: 'POST',
    data
  });

export const inviteUserToOrganization = (organizationId, data) =>
  request({
    url: `/organizations/${organizationId}/invitations`,
    method: 'POST',
    data
  });


