import React from 'react';

// COMPONENTS:
import UserBlock from '../tableComponents/UserBlock';

const CreatedBy = ({ user }) => (
  <td>
    <UserBlock user={user} />
  </td>
);

export default CreatedBy;
