import { confirmEmail } from '../../../api/membership';
import { push } from 'react-router-redux';

export const isConfirming = () => ({
  type: 'IS_EMAIL_CONFIRMING',
});

export const confirmEmailSuccess = () => ({
  type: 'CONFIRM_EMAIL_SUCCESS',
});

export const confirmFailed = errorMessage => ({
  type: 'CONFIRM_EMAIL_FAILED',
  errorMessage,
});

export const confirmEmailAction = (id, code) => (dispatch) => {
  dispatch(isConfirming());
  confirmEmail({ id: id, email_confirmation_code: code })
    .then(() => {
      dispatch(confirmEmailSuccess());
      dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'confirm_success' } });
      dispatch(push('/login'));
    })
    .catch((error) => {
      if (
        error
        && error.data
        && error.data.meta.errors[0]
      ) {
        dispatch(confirmFailed(''));
        error.data.meta.errors.map(
          singleError => dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: singleError.code } }),
        );
      } else {
        dispatch(confirmFailed(''));
        dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'confirm_failed' } });
      }
    });
};
