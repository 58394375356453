import React from 'react';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const AlertErrors = ({ errors, visible }) => {
  if (visible) {
    if (Array.isArray(errors)) {
      return (
        errors.map(error => (
          <Alert key={error.code} color="danger">
            <FormattedMessage id={`Errors.${error.code}`} />
          </Alert>
        ))
      );
    }

    return (
      <Alert color="danger">
        <FormattedMessage id={`Errors.${errors}`} />
      </Alert>
    );
  }
  return null;
};

export default AlertErrors;
