import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonGroup } from 'reactstrap';

// COMPONENTS:
import FilterButton from './FilterButton';

// HELPERS:
const filterValues = [
  'All',
  'OnlyUnassigned',
  'OnlyUnlinked',
  'OnlyLinked',
];

const Filters = ({
  filters = filterValues,
  filterBy,
  changeFilter,
}) => (
  <div className="mb-lg-4">
    <ButtonGroup className="align-items-start align-items-md-center flex-column flex-md-row">
      <span className="mr-3">
        <FormattedMessage id="Filters.show" />:
      </span>

      {filters.map(filterType => (
        <FilterButton
          key={filterType}
          currentFilter={filterBy}
          filterType={filterType}
          changeFilter={changeFilter}
        />
      ))}
    </ButtonGroup>
  </div>
);

export default Filters;
