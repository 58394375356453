import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import GraspName from '../../../../components/GraspName';

// ACTIONS:
import {
  unassignGraspFromEndpointAction,
  removeGraspFromOrganizationAction,
  unlinkGraspFromUserAction,
} from '../reducer';

const GraspsRow = ({
  organizationId,
  id,
  name,
  physical_grasp_uuid,
  endpoint,
  is_linked_to_user,
  is_assigned_to_endpoint,
  unassignGraspFromEndpoint,
  removeGraspFromOrganization,
  unlinkGraspFromUser,
}) => {
  const [actionType, setActionType] = useState('');
  const [actionTypeReady, setActionTypeReady] = useState(false);

  const updateActionType = () => {
    switch (true) {
      case is_linked_to_user:
        setActionType('forceUnlink');
        break;

      case !is_linked_to_user && !is_assigned_to_endpoint:
        setActionType('disown');
        break;

      default:
        setActionType('unassign');
        break;
    }
    setActionTypeReady(true);
  };

  useEffect(
    () => { updateActionType(); },
    [],
  );

  const handleAction = () => {
    switch (actionType) {
      case 'unassign':
        unassignGraspFromEndpoint(organizationId, endpoint.id, id);
        break;

      case 'disown':
        removeGraspFromOrganization(organizationId, id);
        break;

      case 'forceUnlink':
        unlinkGraspFromUser(organizationId, id);
        break;

      default:
        break;
    }
  };

  return (
    actionTypeReady ? (
      <tr>
        <td>
          <GraspName name={name} uuid={physical_grasp_uuid} />
        </td>

        <td>
          {(endpoint && endpoint.name) || 'Not assigned'}
        </td>

        <td>
          {is_linked_to_user ? 'Linked' : 'Not linked'}
        </td>

        <td className="text-right" width="12%">
          <Button
            block
            size="sm"
            color={`${actionType === 'unassign' ? 'primary' : 'danger'}`}
            onClick={handleAction}
          >
            <FormattedMessage id={`OrganizationGrasps.actions.${actionType}`} />
          </Button>
        </td>
      </tr>
    )
      : null
  );
};

const mapDispatchToProps = {
  unassignGraspFromEndpoint: unassignGraspFromEndpointAction,
  removeGraspFromOrganization: removeGraspFromOrganizationAction,
  unlinkGraspFromUser: unlinkGraspFromUserAction,
};

export default connect(
  null,
  mapDispatchToProps,
)(GraspsRow);
