import React, { Fragment } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const Date = ({ date }) => (
  <Fragment>
    {
      date
        ? moment(date).format('L')
        : <FormattedMessage id="GraspInventory.table.notAssigned" />
    }
  </Fragment>
);

export default Date;
