import { resendEmail } from '../../../api/membership';
import { push } from 'react-router-redux';

export const isResending = () => ({
  type: 'IS_EMAIL_RESENDING',
});

export const resendEmailSuccess = () => ({
  type: 'RESEND_EMAIL_SUCCESS',
});

export const resendFailed = errorMessage => ({
  type: 'RESEND_EMAIL_FAILED',
  errorMessage,
});

export const resendEmailAction = (userEmail) => (dispatch) => {
  dispatch(isResending());
  resendEmail({ email: userEmail })
    .then(() => {
      dispatch(resendEmailSuccess());
      dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'resend_success' } });
      dispatch(push('/login'));
    })
    .catch((error) => {
      if (
        error
        && error.data
        && error.data.meta.errors[0]
      ) {
        dispatch(resendFailed(''));
        error.data.meta.errors.map(
          singleError => dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: singleError.code } }),
        );
      } else {
        dispatch(resendFailed(''));
        dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'resend_failed' } });
      }
    });
};
