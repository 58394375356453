import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';

// ACTIONS:
import { logOut } from '../modules/membership/logout/actions';

// COMPONENTS:
import logo from '../assets/img/logo-horiz-white.png';

const Header = ({
  children,
  isLoading,
  logOutAction,
  removeHeaderGap = false,
}) => (
  <Fragment>
    <Navbar
      light
      expand="md"
      color="light"
      className={`${removeHeaderGap ? '' : 'mb-5'} sticky-top bg-gradient`}
    >
      <NavbarBrand tag={Link} to="/">
        <img src={logo} alt="logo" height="20" />
      </NavbarBrand>

      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown
          nav
          inNavbar
          className="small"
        >
          <DropdownToggle className="text-white" nav caret>
            {localStorage.username || localStorage.userEmail || ''}
          </DropdownToggle>

          <DropdownMenu right>
            <DropdownItem
              disabled={isLoading}
              className="small"
              onClick={logOutAction}
            >
              <div className="d-flex align-items-center justify-content-center">
                <FormattedMessage id="Header.logout" />
                <FontAwesomeIcon icon={faSignOut} className="ml-1" />
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>

    </Navbar>
    {children}
  </Fragment>
);

const mapStateToProps = ({
  membership: {
    signingOut,
    signedOut,
  },
}) => ({
  isLoading: signingOut,
  isSuccess: signedOut,
});

const mapDispatchToProps = {
  logOutAction: logOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
