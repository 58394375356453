const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

/**
 * Higher Order Reducer for getting async data
 * @param name - dynamic actions name
 */
const asyncReducers = name => (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case `${name}_STARTED`:
      return {
        isLoading: true,
        isSuccess: false,
        error: null,
      };

    case `${name}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };

    case `${name}_ERROR`:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default asyncReducers;
