import {
  DISMISS_NOTIFICATION,
  DISPLAY_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from './actions';

// INITIAL STATE:
export const initialState = {
  notification: null,
  dismissNotificationId: null,
};

// REDUCER
export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };

    case DISMISS_NOTIFICATION:
      return {
        ...state,
        dismissNotificationId: action.notificationId,
      };

    case CLEAR_NOTIFICATION:
      return initialState;

    default:
      return state;
  }
}
