import React, { useState } from 'react';
import { connect } from "react-redux";

// COMPONENTS:
import RegisterForm from "./components/RegisterForm";
import MembershipHeader from "../MembershipHeader";
import { registerAction } from "../register/actions";

const Register = ({ isLoading, isSuccess, registerFailed, register }) => {
  const [values, setValues] = useState({ firstName: '', lastName: '', email: '', password: '', passwordRepeat: '' });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register(values);
  };

  return (
    <>
      <MembershipHeader id="RegisterForm.createAccount" />
      <RegisterForm
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        registerFailed={registerFailed}
      />
    </>
  );
};

const mapStateToProps = ({ membership: { register } }) => ({
  isLoading: register.isRegistering,
  isSucceed: register.registerSuccess,
  registerFailed: register.registerFailed,
});

const mapDispatchToProps = {
  register: registerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
