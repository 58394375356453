import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

// ACTIONS:
import {
  fetchUsersFromOrganizationAction,
  changeOrganizationUsersPageAction,
  clearOrganizationUsersStoreAction,
} from './reducer';

// COMPONENTS:
import Table from '../../../components/Table';
import UserRow from './components/UserRow';
import UserTableHeader from './components/UserTableHeader';
import Pagination from '../../../components/Pagination';
import InviteModal from './components/inviteModal';

/**
 * Renders list of organization's users
 */
const OrganizationUsers = ({
  isLoading,
  error,
  usersList,
  pagination,
  match,
  fetchUsersFromOrganization,
  changeOrganizationUsersPage,
  clearOrganizationUsersStore,
}) => {
  const changePage = (page) =>
    changeOrganizationUsersPage(page, match.params.id);

  useEffect(() => {
    fetchUsersFromOrganization(match.params.id);
    return () => clearOrganizationUsersStore();
  }, []);
  const [toggleInviteModal, setToggleInviteModal] = useState(false);

  const EmptyListComponent = () => (
    <FormattedMessage id="OrganizationUsers.emptyList" />
  );
  const showInviteModal = () => setToggleInviteModal(!toggleInviteModal);

  return (
    <Container>
      <Row className="mb-5">
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center"
        >
          <h2 className="mb-5">
            <FormattedMessage id="OrganizationUsers.title" />
          </h2>
          <Button
            onClick={showInviteModal}
            type="button"
            color="primary"
            className="mb-5"
          >
            <FormattedMessage id="Organization.invite" />
          </Button>
        </Col>

        <Col xs={12}>
          <Table
            list={usersList}
            isLoading={isLoading}
            isFailed={error}
            tableHeader={UserTableHeader}
            tableRow={UserRow}
            emptyList={EmptyListComponent}
          />
        </Col>

        <Col xs={12}>
          <Pagination pagination={pagination} getData={changePage} />
        </Col>
      </Row>
      <InviteModal
        match={match}
        isOpen={toggleInviteModal}
        toggle={showInviteModal}
      />
    </Container>
  );
};

const mapStateToProps = ({
  organization: {
    users: { list, pagination, isLoading, isSuccess, error },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
  usersList: list,
  pagination,
});

const mapDispatchToProps = {
  fetchUsersFromOrganization: fetchUsersFromOrganizationAction,
  changeOrganizationUsersPage: changeOrganizationUsersPageAction,
  clearOrganizationUsersStore: clearOrganizationUsersStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrganizationUsers));
