import React from 'react';
import { Row, Col } from 'reactstrap';

// COMPONENTS
import Table from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import EventLogHeader from '../../components/EventLogHeader';
import EventLogRow from '../../components/EventLogRow';

const EventLogTable = ({ list, isLoading, error, pagination, changePage }) => {
  const headers = [
    {
      columnName: 'Date',
      translationId: 'EventLog.table.headers.date',
      colWidth: ''
    },
    {
      columnName: 'Time',
      translationId: 'EventLog.table.headers.time',
      colWidth: ''
    },
    {
      columnName: 'Event',
      translationId: 'EventLog.table.headers.event',
      colWidth: ''
    }
    // {
    //   columnName: 'Actions',
    //   translationId: 'EventLog.table.headers.actions',
    //   colWidth: ''
    // }
  ];

  return (
    <Row>
      <Col>
        <Table
          list={list}
          isLoading={isLoading}
          isFailed={!!error}
          tableHeader={EventLogHeader}
          tableRow={EventLogRow}
          headerProps={{ headers }}
        />
        <Pagination pagination={pagination} getData={changePage} />
      </Col>
    </Row>
  );
};

export default EventLogTable;
