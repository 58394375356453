import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/pro-solid-svg-icons';

class LoadingButton extends Component {
  static defaultProps = {
    color: 'primary',
    disabled: false,
    isLoading: false,
    isSucceed: false,
    className: '',
    showCheck: true,
    isFailed: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSuccess: false,
      timeout: null,
    };
    this.timeout = null;
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.isLoading === true &&
      nextProps.isLoading === false &&
      nextProps.isSucceed
    ) {
      this.updateSuccessStatus();
    }
  };

  componentWillUnmount = () => clearTimeout(this.timeout);

  updateSuccessStatus = () => this.setState({ isSuccess: true }, this.disableSuccess);

  disableSuccess() {
    this.timeout = setTimeout(() => {
      this.setState({ isSuccess: false });
      this.changeView();
    }, 500);
  }

  changeView() {
    const {
      goToPage, pushTo, isSucceed, callback,
    } = this.props;
    switch (true) {
      case pushTo !== undefined:
        if (isSucceed) goToPage(pushTo);
        break;
      case callback !== undefined:
        if (isSucceed && callback) callback();
        break;
      default:
        break;
    }
  }

  render() {
    const { isSuccess } = this.state;
    const {
      value,
      color,
      disabled,
      isLoading,
      className,
      isSucceed,
      isFailed,
      callback,
      goToPage,
      pushTo,
      children,
      showCheck,
      ...rest
    } = this.props;

    const opacity = isSuccess || isLoading ? 0 : 1;

    return (
      <Button
        className={
          `d-inline-flex justify-content-center align-items-center
          ${String(className)}`
        }
        color={color}
        disabled={disabled || isLoading}
        {...rest}
      >
        {
          (isLoading || (!showCheck && isSuccess)) && (
          <div className="position-absolute">
            <FontAwesomeIcon icon={faSpinner} spin color="black" />
          </div>
          )}

        {
          showCheck && isSuccess && (
          <span className="position-absolute">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          )}

        {
          value && (
          <span style={{ opacity }}>
            <FormattedMessage id={value} />
          </span>
          )}

        {children && <div style={{ opacity }}>{children}</div>}
      </Button>
    );
  }
}

export default connect(null, { goToPage: push })(LoadingButton);
