import { combineReducers } from 'redux';
import { getGraspInventory, getEndpoints, getOrganizations } from '../../api/organizations';
import withPaginationReducers, { initialState } from '../../helpers/HOR/withPagination/reducer';
import {
  withPaginationActions,
  changePageAction,
  changeFilterByAction,
  changeSortByAction,
  changeSearchQueryAction,
  clearReducerStoreAction,
} from '../../helpers/HOR/withPagination/actions';
import withInfinitePaginationReducers from '../../helpers/HOR/withInfinitePagination/reducer';
import { withInfinitePaginationAction } from '../../helpers/HOR/withInfinitePagination/actions';

const initialStatePageSize = { ...initialState, pageSize: 12 };

// ## INVENTORY: ##

// HELPERS:
const MODULE_NAME = 'GRASP_INVENTORY';
const graspInventoryStore = getState => getState().inventory.grasps;

// REDUCER:
export const graspInventoryReducer = withPaginationReducers(MODULE_NAME);

// ACTIONS:
export const fetchGraspInventory = withPaginationActions(
  MODULE_NAME,
  getGraspInventory,
  graspInventoryStore,
);

export const refreshGraspInventory = withPaginationActions(
  MODULE_NAME,
  getGraspInventory,
  graspInventoryStore,
  true,
);

export const changeInventoryPageAction = changePageAction(MODULE_NAME, fetchGraspInventory);
export const changeInventoryQueryAction = changeSearchQueryAction(MODULE_NAME, fetchGraspInventory);
export const changeInventoryFilterByAction = changeFilterByAction(MODULE_NAME, fetchGraspInventory);
export const changeInventorySortByAction = changeSortByAction(MODULE_NAME, fetchGraspInventory);
export const clearStoreAction = clearReducerStoreAction(MODULE_NAME);


// ## ORGANIZATIONS: ##

// HELPERS:
const ORGANIZATION_MODULE_NAME = `${MODULE_NAME}_ORGANIZATIONS`;
const graspOrganizationsStore = getState => getState().inventory.organizations;

// REDUCER:
const organizationsReducer = withInfinitePaginationReducers(ORGANIZATION_MODULE_NAME, initialStatePageSize);

// ACTIONS:
export const fetchOrganizations = withInfinitePaginationAction(
  ORGANIZATION_MODULE_NAME,
  getOrganizations,
  graspOrganizationsStore,
);

export const changeOrganizationsPageAction = changePageAction(ORGANIZATION_MODULE_NAME, fetchOrganizations);
export const clearOrganizationsStoreAction = clearReducerStoreAction(ORGANIZATION_MODULE_NAME);

// ## ENDPOINTS: ##

// HELPERS:
const ENDPOINTS_MODULE_NAME = `${MODULE_NAME}_ENDPOINTS`;
const graspEndpointsStore = getState => getState().inventory.endpoints;

// REDUCER:
const endpointsReducer = withInfinitePaginationReducers(ENDPOINTS_MODULE_NAME, initialStatePageSize);

// ACTIONS:
export const fetchEndpoints = withInfinitePaginationAction(
  ENDPOINTS_MODULE_NAME,
  getEndpoints,
  graspEndpointsStore,
);

export const changeEndpointsPageAction = changePageAction(ENDPOINTS_MODULE_NAME, fetchEndpoints);
export const clearEndpointsStoreAction = clearReducerStoreAction(ENDPOINTS_MODULE_NAME);

// ## COMBINED REDUCERS: ##
export default combineReducers({
  grasps: graspInventoryReducer,
  organizations: organizationsReducer,
  endpoints: endpointsReducer,
});
