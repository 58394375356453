const initialState = {
  isRegistering: false,
  registerFailed: false,
  registerSuccess: false,
  registerError: [],
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_REGISTERING':
      return {
          ...state,
          isRegistering: true,
          registerFailed: false,
          registerSuccess: false,
        };

    case 'REGISTER_SUCCESS':
      return {
          ...state,
          registerSuccess: true,
          isRegistering: false,
        };

    case 'REGISTER_FAILED':
      return {
          ...state,
          registerError: action.errorMessage,
          registerFailed: true,
          isRegistering: false,
        };

    default:
      return state;
  }
};

export default registerReducer;
