import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// HELPERS:
import {
  notificationCodeTemplates,
  notificationStatusTemplates,
} from './notificationsDefaults';
import generateGUID from '../../helpers/generateGUID';

/**
 * Displays notifications
 */
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifyTL: [],
      notifyTC: [],
      notifyTR: [],
      notifyBL: [],
      notifyBC: [],
      notifyBR: [],
    };
  }

  /**
   * Fire displayNotification while component gets new notification
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.notification !== null &&
      prevProps.notification !== this.props.notification
    ) {
      this.displayNotification(this.props.notification);
    }
    if (prevProps.dismissNotificationId !== this.props.dismissNotificationId) {
      this.onDismiss(this.props.dismissNotificationId);
    }
  }

  /**
   * Dismiss notification
   * @param {String} notificationId Unique identifier of notification
   */
  onDismiss = (notificationId) => {
    const places = [
      'notifyTC',
      'notifyTL',
      'notifyTR',
      'notifyBL',
      'notifyBC',
      'notifyBR',
    ];

    places.forEach((place) => {
      const notifications = this.state[place];

      // Toggle visibility of currently dismissed notification (Fade effect from bootstrap)
      const visibilityChanges = notifications.map((n) => {
        if (n.key && n.key === notificationId)
          return React.cloneElement(n, { isOpen: false });
        return n;
      });

      this.setState({ [place]: visibilityChanges }, () => {
        setTimeout(() => {
          const updatedNotifications = notifications.filter(
            (n) => n.key !== notificationId
          );

          const newState = {};
          newState[place] = updatedNotifications;
          this.setState({ ...newState });
        }, 500);
      });
    });
  };

  /**
   * Create new notification
   */
  notificationAlert = ({
    message,
    autoDismiss = 10, // sec
    icon,
    place = 'tr',
    type = 'danger',
    dismissable = true,
    id,
    jsx = false,
  }) => {
    const notify = this.state[`notify${place.toUpperCase()}`];
    const notificationId = id || generateGUID();

    // Notification component
    const notification = React.createElement(
      Alert,
      {
        isOpen: true,
        color: type,
        toggle: dismissable ? () => this.onDismiss(notificationId) : undefined,
        key: notificationId,
      },
      icon && <span className="mr-2">{React.cloneElement(icon)}</span>,
      typeof message === 'object' && !jsx
        ? React.createElement(FormattedMessage, message)
        : message
    );

    // Set proper order of errors according to place
    if (place.indexOf('b') !== -1) notify.unshift(notification);
    else notify.push(notification);

    const sNotify = {};
    sNotify[`notify${place.toUpperCase()}`] = notify;

    if (autoDismiss > 0) {
      const autoDismissTime = autoDismiss * 1000 + (notify.length - 1) * 1000;
      setTimeout(() => this.onDismiss(notificationId), autoDismissTime);
    }

    this.setState(sNotify);
  };

  /**
   * Render all notifications in selected place
   * @param place
   * @returns {null || Element}
   */
  showAllNotifications = (place) => {
    if (this.state[`notify${place.toUpperCase()}`].length > 0) {
      return React.createElement(
        'div',
        {
          className: `notification notification-${place}`,
        },
        this.state[`notify${place.toUpperCase()}`].map((prop) => prop)
      );
    }

    return null;
  };

  /**
   * Check if notification params match any notification schema and then display proper notification
   * @param notification
   */
  displayNotification(notification) {
    // Checks if notification param has code
    if (notification.code) {
      // Matching notification code to existing notification schemas
      const notificationTemplate = {
        ...(notificationCodeTemplates.find(
          (n) => n.code === notification.code
        ) ||
          notificationStatusTemplates.find(
            (n) => n.status === notification.code
          )),
      };

      // Check if notificationTemplate is empty
      if (Object.keys(notificationTemplate).length) {
        this.notificationAlert({
          ...notificationTemplate,
          id: notification.id,
        });
      }
    } else if (notification.jsx) {
      this.notificationAlert({
        message: notification.jsx,
        jsx: true,
        dismissable: true,
        autoDismiss: 7,
        type: 'warning',
      });
    }
  }

  render() {
    return React.createElement(
      'div',
      { ref: 'notifications' },
      this.showAllNotifications('tl'),
      this.showAllNotifications('tc'),
      this.showAllNotifications('tr'),
      this.showAllNotifications('bl'),
      this.showAllNotifications('bc'),
      this.showAllNotifications('br')
    );
  }
}

const mapStateToProps = ({ notifications }) => ({
  notification: notifications.notification,
  dismissNotificationId: notifications.dismissNotificationId,
});

export default connect(mapStateToProps, null)(Notification);
