import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

// Actions
import {
  fetchGraspsFromOrganizationAction,
  changeOrganizationGraspsPageAction,
  changeOrganizationGraspsFilterAction,
  clearOrganizationGraspsStoreAction,
} from './reducer';

// Components
import Table from '../../../components/Table';
import GraspRow from './components/GraspRow';
import GraspTableHeader from './components/GraspTableHeader';
import Filters from '../../../components/Filters';
import Pagination from '../../../components/Pagination';

/**
 * Renders list of organization's users
 */
const OrganizationGrasps = ({
  grasps,
  pagination,
  isLoading,
  error,
  filterBy,
  fetchGraspsFromOrganization,
  changeOrganizationGraspsPage,
  changeOrganizationGraspsFilter,
  clearOrganizationGraspsStore,
  match,
}) => {
  const getGrasps = () => {
    fetchGraspsFromOrganization(match.params.id);
  };

  const changeFilter = filterType => changeOrganizationGraspsFilter(filterType, match.params.id);

  const changePage = page => changeOrganizationGraspsPage(page, match.params.id);

  useEffect(
    () => {
      getGrasps();
      return clearOrganizationGraspsStore;
    },
    [],
  );

  return (
    <Container>
      <Row className="mb-5">
        <Col xs={12}>
          <h2>
            <FormattedMessage id="EndpointDetails.graspManagement" />
          </h2>
        </Col>
        <Col>
          <Filters
            filterBy={filterBy}
            changeFilter={changeFilter}
            pagination={pagination}
          />
        </Col>
        <Col xs={12}>
          <Table
            list={grasps}
            isLoading={isLoading}
            isFailed={error}
            tableHeader={GraspTableHeader}
            tableRow={GraspRow}
            rowProps={{ organizationId: match.params.id }}
          />
        </Col>

        <Col xs={12}>
          <Pagination
            pagination={pagination}
            getData={changePage}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({
  organization: {
    grasps: {
      list,
      pagination,
      isLoading,
      error,
      filterBy,
    },
  },
}) => ({
  isLoading,
  error,
  grasps: list,
  pagination,
  filterBy,
});

const mapDispatchToProps = {
  fetchGraspsFromOrganization: fetchGraspsFromOrganizationAction,
  changeOrganizationGraspsPage: changeOrganizationGraspsPageAction,
  changeOrganizationGraspsFilter: changeOrganizationGraspsFilterAction,
  clearOrganizationGraspsStore: clearOrganizationGraspsStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrganizationGrasps));
