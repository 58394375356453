import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, Col } from 'reactstrap';

/**
 * Endpoint item (Card) rendered in OrganizationEndpoints list
 */
const EndpointsItem = ({
  endpoint: {
    id,
    name,
    grasps_count,
  },
}) => (
  <Col xs={12} md={6} lg={4}>
    <Card
      className="my-2 text-decoration-none text-dark"
      tag={Link}
      to={`endpoints/${id}`}
    >
      <CardBody className="p-5">
        <h6 className="text-center text-truncate">{name}</h6>

        <p className="text-center mb-0">
          <FormattedMessage
            id="OrganizationEndpoints.graspsCount"
            values={{ count: grasps_count }}
          />
        </p>
      </CardBody>
    </Card>
  </Col>
);

export default EndpointsItem;
