import Cookie from 'js-cookie';

export const getToken = () => {
  const name = 'access_token=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const isAuthenticated = () => !!getToken();

export const hasMailInStorage = () => !!sessionStorage.getItem('email');

export const getAccessToken = () => {
  return Cookie.get('access_token');
};

export const setTokens = (accessToken, validUntil) => {
  Cookie.set('access_token', accessToken);
  Cookie.set('token_expiration_date', new Date(validUntil).toISOString());
};

export const deleteTokens = () => {
  Cookie.remove('access_token');
  Cookie.remove('token_expiration_date');
  localStorage.removeItem('user');
  localStorage.removeItem('userEmail');
};
