import axios from 'axios';
import { isAuthenticated, getToken } from '../helpers/authentication';
import { getUrlFromDiscoveryService } from './membership';

// API:
import { refreshTokenInterceptor } from './refreshTokenInterceptor';

/**
 * Create an Axios Client with defaults
 */

const defaultValues = {
  baseURL: `${localStorage.getItem('api_url')}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
};

const client = axios.create(defaultValues);

/**
 * Request Wrapper with default success/error actions
 */
const request = async (options) => {
  if (!localStorage.getItem('api_url')) {
    const { data } = await getUrlFromDiscoveryService();

    if (data.url) {
      localStorage.setItem('api_url', data.url);
    }
  }

  client.defaults.baseURL = `${localStorage.getItem('api_url')}/api`;

  if (isAuthenticated()) {
    client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
  } else {
    client.defaults.headers.common.Authorization = ``;
  }

  const onSuccess = (response) => response;
  const onError = (error) => {
    /* eslint-disable no-console */
    if (error.response && process.env.NODE_ENV === 'development') {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

refreshTokenInterceptor(client);

export default request;
