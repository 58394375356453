import React from 'react';
import { FormattedMessage } from 'react-intl';

const EventLogHeader = ({ headers }) => (
  <thead>
    <tr>
      {headers.map(({ columnName, translationId, colWidth }) => (
        <th key={columnName} style={{ minWidth: colWidth }}>
          <FormattedMessage id={translationId} />
        </th>
      ))}
    </tr>
  </thead>
);

export default EventLogHeader;
