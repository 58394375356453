export default class LocalLanguage {
  constructor() {
    this.defaultLaguage = 'en';
    this.localCulture = this.getCulture();
    this.usedTranslations = ['no', 'en'];
  }

  /**
   * Check browser language
   * @returns {String} example: 'en-US'
   */
  getCulture() {
    return window.navigator.languages
      ? window.navigator.languages[0]
      : window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage;
  }

  checkIfPrefferedLanguage = (lang) => {
    let matchedTranslations = false;

    this.usedTranslations.forEach((uT) => {
      if (lang.includes(uT)) matchedTranslations = true;
    });

    return matchedTranslations;
  }


  /**
   * Remove region code from language string
   * (from 'en-US' to 'en')
   * @returns {String}
   */
  getLangWithoutRegion() {
    const language = this.getCulture();
    return this.checkIfPrefferedLanguage(language) ? language.substr(0, 2) : this.defaultLaguage;
  }
}
