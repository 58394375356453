import { push } from "react-router-redux";
import { register } from '../../../api/membership';

export const isRegistering = () => ({
  type: 'IS_REGISTERING',
});

export const registerSuccess = () => ({
  type: 'REGISTER_SUCCESS',
});

export const registerFailed = errorMessage => ({
  type: 'REGISTER_FAILED',
  errorMessage,
});

export const registerAction = (values) => (dispatch) => {
  dispatch(isRegistering());
  const arePasswordsEqual = () =>
    values.password !== '' && values.password === values.passwordRepeat;

  if (!arePasswordsEqual()) {
    return dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'passwords_must_match' } });
  } else {
    const model = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      culture: 'en'
    };
    return register(model)
      .then(() => {
        dispatch(registerSuccess());
        dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'register_success' } });
        dispatch(push('/login'));
      })
      .catch((error) => {
        if (
          error
          && error.data
          && error.data.meta.errors[0]
        ) {
          dispatch(registerFailed(''));
          error.data.meta.errors.map(
            singleError => dispatch({type: 'DISPLAY_NOTIFICATION', notification: {code: singleError.code}}),
          );
        } else {
          dispatch(registerFailed(''));
          dispatch({type: 'DISPLAY_NOTIFICATION', notification: {code: 'register_failed'}});
        }
      });
  }
};
