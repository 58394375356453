import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col } from 'reactstrap';

// COMPONENTS:
import OrganizationsList from './OrganizationsList';
import AddOrganizationModal from './AddOrganizationModal';

const OrganizationSection = () => {
  const [isAddOrganizationModalOpen, updateAddOrganizationModalState] = useState(false);
  const toggleAddOrganizationModal = () => updateAddOrganizationModalState(!isAddOrganizationModalOpen);

  return (
    <>
      <Row>
        <Col xs={12} md={8}>
          <h2>
            <FormattedMessage id="Dashboard.organizations" />
          </h2>
        </Col>

        <Col xs={12} md={4} className="text-md-right">
          <Button
            onClick={toggleAddOrganizationModal}
            type="button"
            color="primary"
          >
            <FormattedMessage id="Dashboard.addOrganization" />
          </Button>
        </Col>
      </Row>
      <OrganizationsList />
      {
        isAddOrganizationModalOpen && (
        <AddOrganizationModal
          isOpen={isAddOrganizationModalOpen}
          toggle={toggleAddOrganizationModal}
        />
        )}
    </>
  );
};

export default OrganizationSection;
