import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// HELPERS:
import dispatchAction from '../../../../../helpers/utils/dispatchAction';

// API:
import {
  unassignGraspFromEndpoint,
  removeGraspFromOrganization,
  unlinkGraspFromUser,
  addDeviceToEndpoint,
  assignDeviceToOrganization,
} from '../../../../../api/organizations';

// ACTIONS:
import { refreshGraspInventory } from '../../../reducer';
import { displayNotificationAction } from '../../../../notifications/actions';

// COMPONENTS:
import AssignToOrganizationModal from './OrganizationsModal';
import AssignToEndpointModal from './EndpointsModal';
import ScrapModal from './ScrapModal';

const ActionButton = ({
  name,
  serialNumber,
  graspId,
  organization,
  endpoint,
  hasOwner,
  isAssignedToOrganization,
  isAssignedToEndpoint,
  refreshInventory,
  displayNotification,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionTypeFunction, setActionTypeFunction] = useState(null);
  const [isAssignToOrganizationModalOpen, setOrganizationModalOpen] = useState(
    false
  );
  const [isAssignToEndpointModalOpen, setEndpointModalOpen] = useState(false);
  const [isScrapGraspModalOpen, setIsScrapGraspModalOpen] = useState(false);

  const action = dispatchAction(
    setIsLoading,
    refreshInventory,
    displayNotification
  );

  const unlinkUser = () =>
    action(unlinkGraspFromUser, 'grasp_unlinked', organization.id, graspId);
  const unassignFromEndpoint = () =>
    action(
      unassignGraspFromEndpoint,
      'grasp_unassigned',
      organization.id,
      endpoint.id,
      graspId
    );
  const removeFromOrganization = () =>
    action(
      removeGraspFromOrganization,
      'grasp_disowned',
      organization.id,
      graspId
    );
  const assignToOrganization = (organizationId) =>
    action(
      assignDeviceToOrganization,
      'grasp_assigned',
      organizationId,
      graspId
    );
  const assignToEndpoint = (endpointId) =>
    action(addDeviceToEndpoint, 'grasp_assigned', organization.id, endpointId, {
      grasp_id: graspId,
    });

  const toggleOrganizationsModal = () =>
    setOrganizationModalOpen((prev) => !prev);
  const toggleEndpointsModal = () => setEndpointModalOpen((prev) => !prev);
  const toggleScrapModal = () => setIsScrapGraspModalOpen((prev) => !prev);

  const updateActionType = () => {
    switch (true) {
      case hasOwner:
        setActionType('forceUnlink');
        setActionTypeFunction(unlinkUser);
        break;

      case isAssignedToOrganization && isAssignedToEndpoint && !hasOwner:
        setActionType('unassign');
        setActionTypeFunction(unassignFromEndpoint);
        break;

      case !isAssignedToOrganization && !isAssignedToEndpoint:
        setActionType('assignToOrganization');
        setActionTypeFunction(() => toggleOrganizationsModal);
        break;

      default:
        setActionType('disown/assign');
        setActionTypeFunction(removeFromOrganization);
        break;
    }
  };

  const handleClick = () => actionTypeFunction();

  useEffect(() => {
    updateActionType();
  }, [hasOwner, isAssignedToOrganization, isAssignedToEndpoint]);

  const renderButton = () => {
    if (!actionType) return null;

    if (actionType === 'disown/assign') {
      return (
        <ButtonGroup size="sm">
          <Button
            disabled={isLoading || disabled}
            data-type="disown"
            color="danger"
            onClick={handleClick}
          >
            <FormattedMessage id="OrganizationGrasps.actions.disown" />
          </Button>
          <Button
            disabled={isLoading || disabled}
            data-type="assign"
            color="primary"
            onClick={toggleEndpointsModal}
          >
            <FormattedMessage id="OrganizationGrasps.actions.assign" />
          </Button>
        </ButtonGroup>
      );
    }

    const buttonColor = actionType === 'forceUnlink' ? 'danger' : 'primary';

    return (
      <Button
        size="sm"
        disabled={isLoading || disabled}
        block
        color={buttonColor}
        onClick={handleClick}
      >
        <FormattedMessage id={`OrganizationGrasps.actions.${actionType}`} />
      </Button>
    );
  };

  return (
    <td>
      <div className="d-flex">
        {renderButton()}
        {organization.id !== null && (
          <Button
            disabled={disabled}
            size="sm"
            className="ml-2"
            color="danger"
            onClick={toggleScrapModal}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        )}
      </div>
      {isAssignToOrganizationModalOpen && (
        <AssignToOrganizationModal
          isOpen={isAssignToOrganizationModalOpen}
          toggle={toggleOrganizationsModal}
          action={assignToOrganization}
        />
      )}

      {isAssignToEndpointModalOpen && (
        <AssignToEndpointModal
          isOpen={isAssignToEndpointModalOpen}
          toggle={toggleEndpointsModal}
          organizationId={organization.id || null}
          action={assignToEndpoint}
        />
      )}
      {isScrapGraspModalOpen && (
        <ScrapModal
          isOpen={isScrapGraspModalOpen}
          toggle={toggleScrapModal}
          name={name}
          serialNumber={serialNumber}
          graspId={graspId}
          organizationId={organization.id}
        />
      )}
    </td>
  );
};

const mapDispatchToProps = {
  refreshInventory: refreshGraspInventory,
  displayNotification: displayNotificationAction,
};

export default connect(null, mapDispatchToProps)(ActionButton);
