import { combineReducers } from 'redux';

// REDUCERS:
import {
  organizationDashboardReducer,
  editOrganizationReducer,
  addEndpointReducer,
  addGraspReducer,
} from './dashboard/reducer';
import { addOrganizationReducer } from '../dashboard/reducer';
import { endpointsReducer } from './endpoints/reducer';
import {
  endpointDetailsReducer,
  editEndpointReducer,
} from './endpoint/reducer';
import { linkedGraspsReducer } from './endpoint/components/LinkedGraspsTable/reducer';
import { unlinkedGraspsReducer } from './endpoint/components/UnlinkedGraspsTable/reducer';
import { unassignedGraspsReducer } from './endpoint/components/GraspsPoolTable/reducer';
import { organizationUsersReducer } from './users/reducer';
import { organizationGraspsReducer } from './grasps/reducer';
import { inviteUserReducer } from './users/reducer';

export default combineReducers({
  details: organizationDashboardReducer,
  creating: addOrganizationReducer,
  updating: editOrganizationReducer,
  creatingGrasp: addGraspReducer,
  unassignedGrasps: unassignedGraspsReducer,
  endpoints: endpointsReducer,
  endpoint: combineReducers({
    details: endpointDetailsReducer,
    creating: addEndpointReducer,
    updating: editEndpointReducer,
    linkedGrasps: linkedGraspsReducer,
    unlinkedGrasps: unlinkedGraspsReducer,
  }),
  invitationUser: inviteUserReducer,
  users: organizationUsersReducer,
  grasps: organizationGraspsReducer,
});
