import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// ACTIONS:
import { fetchOrganizationsAction, changeOrganizationsPageAction } from '../reducer';

// COMPONENTS:
import OrganizationItem from './OrganizationItem';
import Pagination from '../../../components/Pagination';
import LoadingSpinner from '../../../components/LoadingSpinner';

const OrganizationsList = ({
  list,
  pagination,
  isLoading,
  isSuccess,
  error,
  fetchOrganizationsList,
  changeOrganizationsPage,
}) => {
  useEffect(
    () => { fetchOrganizationsList(); },
    [],
  );

  const changePage = page => changeOrganizationsPage(page);
  const isListEmpty = isSuccess && !list.length;

  switch (true) {
    case isLoading:
      return <LoadingSpinner className="pt-5 text-center" isLoading={isLoading} size="2x" />;

    case isListEmpty:
      return (
        <div className="py-5 text-center">
          <FormattedMessage id="Dashboard.emptyList" />
        </div>
      );

    case isSuccess:
      return (
        <>
          <Row className="mt-3">
            {
              list.map(organization => (
                <OrganizationItem
                  key={organization.id}
                  organization={organization}
                />
              ))
            }
          </Row>

          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <Pagination
                pagination={pagination}
                getData={changePage}
              />
            </Col>
          </Row>
        </>
      );

    case error: {
      return <div><FormattedMessage id="Errors.organizations_error" /></div>;
    }

    default:
      return null;
  }
};


const mapStateToProps = ({
  dashboard: {
    organizations: {
      list,
      isLoading,
      isSuccess,
      error,
      pagination,
    },
  },
}) => ({
  list,
  isLoading,
  isSuccess,
  error,
  pagination,
});

const mapDispatchToProps = {
  fetchOrganizationsList: fetchOrganizationsAction,
  changeOrganizationsPage: changeOrganizationsPageAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationsList);
