import { combineReducers } from 'redux';
import loginReducer from "./login/reducer";
import logoutReducer from "./logout/reducer";
import passwordResetReducer from "./password-reset/reducer";
import resendEmailReducer from "./resend-email/reducer";
import confirmEmailReducer from "./confirm-email/reducer";
import setPasswordReducer from "./set-password/reducer";
import registerReducer from "./register/reducer";

export default combineReducers({
  login: loginReducer,
  logout: logoutReducer,
  passwordReset: passwordResetReducer,
  setPassword: setPasswordReducer,
  register: registerReducer,
  resendEmail: resendEmailReducer,
  confirmEmail: confirmEmailReducer,
});
