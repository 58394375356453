import React from 'react';
import { FormattedTime } from 'react-intl';

const EventLogTime = ({ time }) => (
  <td>
    {
      <FormattedTime
        value={time}
        hour12={false}
        hour="numeric"
        minute="numeric"
        second="numeric"
      />
    }
  </td>
);

export default EventLogTime;
