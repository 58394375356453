import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faSignIn } from '@fortawesome/pro-solid-svg-icons/index';
import { Form, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom'

// COMPONENTS:
import InputCustom from '../../../../components/InputCustom';
import LoadingButton from '../../../../components/LoadingButton';

const LoginForm = ({
  intl: { formatMessage },
  loginFailed,
  values,
  isLoading,
  isSucceed,
  handleSubmit,
  handleChange,
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup>
      <InputCustom
        required
        id="login-username"
        name="username"
        type="email"
        value={values.username}
        onChange={handleChange}
        placeHolder={formatMessage({ id: 'LoginForm.username' })}
      />
    </FormGroup>

    <FormGroup>
      <InputCustom
        required
        name="password"
        type="password"
        id="login-password"
        value={values.password}
        onChange={handleChange}
        placeHolder={formatMessage({ id: 'LoginForm.password' })}
      />
    </FormGroup>

    <div className="text-center">
      <LoadingButton
        isLoading={isLoading}
        isSucceed={isSucceed}
        isFailed={loginFailed}
        value="LoginForm.login"
        pushTo="/"
        type="submit"
        className="btn btn-outline-light btn-block btn-login"
      >
        <FontAwesomeIcon icon={faSignIn} className="ml-1" />
      </LoadingButton>
    </div>
    <div className="mt-2 text-center">
      <Link to="password-reset" className="text-white">
        <FormattedMessage id="LoginForm.forgotPassword" />
      </Link>
      <span className="mx-2 text-white">
        |
      </span>
      <Link to="register" className="text-white">
        <FormattedMessage id="LoginForm.createAccount" />
      </Link>
    </div>
  </Form>
);

export default injectIntl(LoginForm);
