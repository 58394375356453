import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

// ACTIONS:
import {
  fetchEventLogs,
  clearEventLogsStoreAction,
  changeEventLogsPageAction
} from './reducer';
import EventLogTable from './components/EventLogTable';
// import EventLogFilters from './components/EventLogFilters';
import EventLogBackButton from './components/EventLogBackButton';
import EventLogGrasp from './components/EventLogGrasp';
import { getGraspFromOrganization } from '../../api/eventLogs';

const EventLogs = ({
  getEventLogs,
  clearEventLogsStore,
  changeEventLogsPage,
  match,
  list,
  isLoading,
  error,
  pagination,
  location
}) => {
  const [graspData, setGraspData] = useState(null);

  useEffect(() => {
    getEventLogs(match.params.graspId);
    if (!location.state) {
      getGraspFromOrganization(match.params.id, match.params.graspId).then(
        response => {
          setGraspData({
            name: response.data.data.name,
            hex_color: response.data.data.hex_color
          });
        }
      );
    } else {
      setGraspData({
        name: location.state.name,
        hex_color: location.state.hex_color
      });
    }
    return () => clearEventLogsStore();
  }, []);

  const changePage = page => changeEventLogsPage(page, match.params.graspId);

  return (
    <Container>
      <EventLogBackButton />
      <EventLogGrasp grasp={graspData} />
      {/* <EventLogFilters /> */}
      <EventLogTable
        list={list}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
        changePage={changePage}
      />
    </Container>
  );
};

const mapStateToProps = ({
  eventLogs: { list, pagination, isLoading, error }
}) => ({
  list,
  pagination,
  isLoading,
  error
});

const mapDispatchToProps = {
  getEventLogs: fetchEventLogs,
  changeEventLogsPage: changeEventLogsPageAction,
  clearEventLogsStore: clearEventLogsStoreAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventLogs);
