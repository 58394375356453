import React from 'react';

// COMPONENTS:
import Date from '../tableComponents/Date';

const CreatedAt = ({ date }) => (
  <td className="text-center">
    <Date date={date} />
  </td>
);

export default CreatedAt;
