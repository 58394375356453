import { assignGraspToEndpoint, getOrganizationGrasps } from '../../../../../api/organizations';
import { asyncActions } from '../../../../../helpers/HOR/asyncAction/actions';
import { fetchUnlinkedGraspsAction } from '../UnlinkedGraspsTable/reducer';
import {
  withInfinitePaginationAction,
  changePageAction,
  clearReducerStoreAction,
} from '../../../../../helpers/HOR/withInfinitePagination/actions';
import withInfinitePaginationReducers, { initialState }
  from '../../../../../helpers/HOR/withInfinitePagination/reducer';

// HELPERS:
const UNASSIGNED_GRASPS = 'UNASSIGNED_GRASPS';
const unassignedGraspsInitialState = { ...initialState, filterBy: 'OnlyUnassigned', pageSize: 12 };
const unassignedGraspsStore = getState => getState().organization.unassignedGrasps;

const ASSIGN_GRASP_TO_ENDPOINT = 'ASSIGN_GRASP_TO_ENDPOINT';

// REDUCER:
export const unassignedGraspsReducer = withInfinitePaginationReducers(UNASSIGNED_GRASPS, unassignedGraspsInitialState);

// ACTIONS:
export const fetchUnassignedGraspsAction = withInfinitePaginationAction(
  UNASSIGNED_GRASPS,
  getOrganizationGrasps,
  unassignedGraspsStore,
);

export const clearUnassignedGraspsStoreAction = clearReducerStoreAction(UNASSIGNED_GRASPS);

const assignGraspAsyncActionParameters = {
  name: ASSIGN_GRASP_TO_ENDPOINT,
  APICall: assignGraspToEndpoint,
  callback: (data, organizationId, endpointId) => (dispatch) => {
    dispatch(clearUnassignedGraspsStoreAction());
    dispatch(fetchUnassignedGraspsAction(organizationId, endpointId));
    dispatch(fetchUnlinkedGraspsAction(organizationId, endpointId));
  },
  onSuccessNotification: { code: 'grasp_assigned' },
};

export const assignGraspToEndpointAction = asyncActions(assignGraspAsyncActionParameters);
export const changeUnassignedGraspPageAction = changePageAction(UNASSIGNED_GRASPS, fetchUnassignedGraspsAction);
