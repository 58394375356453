import { push } from 'react-router-redux';
import { signOut } from '../../../api/membership';

// HELPERS:
import { deleteTokens } from '../../../helpers/authentication';

export const signingOut = () => ({
  type: 'SIGNING_OUT',
});

export const signedOut = () => ({
  type: 'SIGNED_OUT',
});

export const signingOutFail = (error) => ({
  type: 'SIGNING_OUT_FAILED',
  error,
});

export const logOut = () => (dispatch) => {
  dispatch(signingOut());
  signOut()
    .then(() => {
      deleteTokens();
      dispatch(signedOut());
      localStorage.removeItem('api_url');
      dispatch(push('/login'));
    })
    .catch(() => {
      deleteTokens();
      dispatch(signingOutFail());
      localStorage.removeItem('api_url');
      dispatch(push('/login'));
    });
};
