import React from 'react';

const UserRow = ({
  email,
  first_name,
  last_name,
}) => (
  <tr>
    <td>
      {email || '-'}
    </td>
    <td>
      {first_name || '-'}
    </td>
    <td>
      {last_name || '-'}
    </td>
  </tr>
);

export default UserRow;
