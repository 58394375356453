import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';

const longDismissTime = 5; // sec
// const shortDismissTime = 3; // sec
const defaultDismissTime = longDismissTime; // sec
const defaultErrorIcon = <FontAwesomeIcon icon={faExclamationTriangle} />;
const defaultSuccessIcon = <FontAwesomeIcon icon={faCheckCircle} />;

export const notificationCodeTemplates = [
  {
    code: 'user_added',
    message: { id: 'Notification.user_added' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'grasp_disowned',
    message: { id: 'Notification.grasp_disowned' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'grasp_added',
    message: { id: 'Notification.grasp_added' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'endpoint_added',
    message: { id: 'Notification.endpoint_added' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'endpoint_updated',
    message: { id: 'Notification.endpoint_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'organization_updated',
    message: { id: 'Notification.organization_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'organization_added',
    message: { id: 'Notification.organization_added' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'grasp_assigned',
    message: { id: 'Notification.grasp_assigned' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'grasp_unassigned',
    message: { id: 'Notification.grasp_unassigned' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'grasp_disowned',
    message: { id: 'Notification.organization_disowned' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'grasp_force_unlink',
    message: { id: 'Notification.grasp_force_unlink' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'flashdate_updated',
    message: { id: 'Notification.flashdate_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'numbertag_updated',
    message: { id: 'Notification.numbertag_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'serialnumber_updated',
    message: { id: 'Notification.serialnumber_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'serialnumber_already_exist',
    message: { id: 'Notification.serialnumber_already_exist' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'danger',
  },
  {
    code: 'grasp_already_exists_error',
    message: { id: 'Notification.grasp_already_exists_error' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'danger',
  },
  {
    code: 'graspInventoryStatus_updated',
    message: { id: 'Notification.graspInventoryStatus_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'hex_color_updated',
    message: { id: 'Notification.hex_color_updated' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'password_reset_success',
    message: { id: 'ResetPassword.password_reset_success' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'set_password_success',
    message: { id: 'SetPassword.set_password_success' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'register_success',
    message: { id: 'Register.register_success' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'resend_success',
    message: { id: 'ResendEmail.resend_success' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'confirm_success',
    message: { id: 'ConfirmEmail.confirm_success' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultSuccessIcon,
    type: 'success',
  },
  {
    code: 'email',
    message: { id: 'Errors.email' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'password',
    message: { id: 'Errors.password' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'dont_match',
    message: { id: 'Errors.dont_match' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'organization_id_invalid',
    message: { id: 'Errors.organization_id_invalid' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'physical_grasp_uuid_invalid',
    message: { id: 'Errors.physical_grasp_uuid_invalid' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'endpoint_id_invalid',
    message: { id: 'Errors.endpoint_id_invalid' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'grasp_already_in_endpoint_error',
    message: { id: 'Errors.grasp_already_in_endpoint_error' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'grasp_does_not_exist_error',
    message: { id: 'Errors.grasp_does_not_exist_error' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'grasp_id_invalid',
    message: { id: 'Errors.grasp_id_invalid' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'user_does_not_exist_error',
    message: { id: 'Errors.user_does_not_exist_error' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'email_invalid',
    message: { id: 'Errors.email_invalid' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'password_invalid',
    message: { id: 'Errors.password_invalid' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'login_failed',
    message: { id: 'Errors.login_failed' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'passwords_must_match',
    message: { id: 'Errors.passwords_must_match' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'register_failed',
    message: { id: 'Errors.register_failed' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'confirm_failed',
    message: { id: 'Errors.confirm_failed' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'resend_failed',
    message: { id: 'Errors.resend_failed' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'set_password_failed',
    message: { id: 'Errors.set_password_failed' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'reset_password_failed',
    message: { id: 'Errors.reset_password_failed' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'user_email_not_confirmed_error',
    message: { id: 'Errors.user_email_not_confirmed_error' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    code: 'session_timeout',
    message: { id: 'Errors.session_timeout' },
    dismissable: true,
    autoDismiss: defaultDismissTime,
    icon: defaultErrorIcon,
    type: 'warning',
  },
];

export const notificationStatusTemplates = [
  {
    status: 400,
    message: { id: 'Errors.bad_request' },
    dismissable: true,
    autoDismiss: 0,
    icon: defaultErrorIcon,
    type: 'danger',
  },
  {
    status: 500,
    message: { id: 'Errors.500' },
    dismissable: true,
    autoDismiss: 0,
    icon: defaultErrorIcon,
    type: 'danger',
  },
];
