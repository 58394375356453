import React from 'react';

// COMPONENTS:
import ExtendableRow from '../../../components/Table/ExtendableRow';
import Row from './InventoryRow';
import Extender from './InventoryExtendedRow';

const InventoryExtendableRow = props => (
  <ExtendableRow
    rowComponent={Row}
    extenderComponent={Extender}
    {...props}
  />
);

export default InventoryExtendableRow;
