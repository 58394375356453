import { getEventLogs } from '../../api/eventLogs';
import {
  withPaginationActions,
  changePageAction,
  clearReducerStoreAction
} from '../../helpers/HOR/withPagination/actions';
import withPaginationReducers, {
  initialState
} from '../../helpers/HOR/withPagination/reducer';

const initialStatePageSize = { ...initialState, pageSize: 10 };

// HELPERS:
const MODULE_NAME = 'EVENT_LOGS';
const eventLogsStore = getState => getState().eventLogs;

// ACTIONS:
export const fetchEventLogs = withPaginationActions(
  MODULE_NAME,
  getEventLogs,
  eventLogsStore
);

export const clearEventLogsStoreAction = clearReducerStoreAction(MODULE_NAME);

// REDUCER:
export const eventLogsReducer = withPaginationReducers(
  MODULE_NAME,
  initialStatePageSize
);

export const changeEventLogsPageAction = changePageAction(
  MODULE_NAME,
  fetchEventLogs
);
