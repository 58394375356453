import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import request from '../../../../api/request';
import debounce from '../../../../helpers/utils/debounce';

import 'react-datepicker/dist/react-datepicker.css';
import InputCustom from '../../../../components/InputCustom';
import { displayNotificationAction } from '../../../notifications/actions';

const FlashDate = ({
  date,
  organizationId,
  graspId,
  intl,
  displayNotification,
  disabled,
}) => {
  const dateParsedFromIso = Date.parse(date);
  const [dateValue, setDateValue] = useState(dateParsedFromIso);

  const changeGraspFlashDate = (value) =>
    request({
      url: `/organizations/${organizationId}/grasps/${graspId}`,
      method: 'PATCH',
      data: {
        flashdate: value,
      },
    }).then(() => displayNotification({ code: 'flashdate_updated' }));

  const debouncedChangeGraspFlashDate = debounce(changeGraspFlashDate, 500);

  const handleChange = (dateParam) => {
    setDateValue(dateParam);
    const dateISO = dateParam.toISOString();
    debouncedChangeGraspFlashDate(dateISO);
  };

  return (
    <td className="text-center">
      <DatePicker
        withPortal
        customInput={
          <InputCustom autoComplete="false" style={{ width: '150px' }} />
        }
        disabled={!organizationId || disabled}
        selected={dateValue}
        onChange={handleChange}
        dateFormat="MMMM d, yyyy"
        placeholderText={intl.formatMessage({
          id: 'GraspInventory.table.notAssigned',
        })}
      />
    </td>
  );
};

const mapDispatchToProps = {
  displayNotification: displayNotificationAction,
};

export default connect(null, mapDispatchToProps)(injectIntl(FlashDate));
