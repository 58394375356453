const DEFAULT_PAGE_SIZE = 10;

export const initialState = {
  list: [],
  filterBy: 'All',
  sortBy: null,
  pageSize: DEFAULT_PAGE_SIZE,
  pagination: {},
  isLoading: false,
  isSuccess: false,
  error: null,
  meta: null,
};

/**
 * Higher Order Reducer
 * @param name - dynamic actions name
 * @param reducerState - initial reducer state
 */
const withPaginationReducers = (name, reducerState = initialState) => (
  state = reducerState,
  action,
) => {
  switch (action.type) {
    case `FETCH_${name}_STARTED`:
      return {
        ...state,
        list: [],
        isLoading: true,
        isSuccess: false,
        error: null,
      };

    case `REFRESH_${name}_STORE`:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };

    case `FETCH_${name}_SUCCESS`:
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        isLoading: false,
        isSuccess: true,
        meta: action.meta,
      };

    case `FETCH_${name}_ERROR`:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case `UPDATE_${name}_FILTER_BY`:
      return {
        ...state,
        filterBy: action.filterBy,
        pagination: {
          ...state.pagination,
          current_page: 1,
        },
      };

    case `UPDATE_${name}_SORT_BY`:
      return {
        ...state,
        sortBy: action.sortBy,
      };

    case `CHANGE_${name}_PAGE`:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current_page: action.page,
        },
      };

    case `CHANGE_${name}_SEARCH_QUERY`:
      return {
        ...state,
        query: action.query,
        pagination: {
          ...state.pagination,
          current_page: 1,
        },
      };

    case `CLEAR_${name}_STORE`:
      return reducerState;

    default:
      return state;
  }
};

export default withPaginationReducers;
