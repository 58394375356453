import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import InputCustom from '../../../../components/forms/InputCustom';
import request from '../../../../api/request';
import debounce from '../../../../helpers/utils/debounce';
import { displayNotificationAction } from '../../../notifications/actions';

const Status = ({
  status,
  disabled,
  statusOptions,
  graspId,
  organizationId,
  intl,
  displayNotification,
}) => {
  const [statusOptionValue, setStatusOptionValue] = useState(status || '');

  const changeGraspStatus = (value) =>
    request({
      url: `/organizations/${organizationId}/grasps/${graspId}`,
      method: 'PATCH',
      data: {
        status: value,
      },
    }).then(() =>
      displayNotification({ code: 'graspInventoryStatus_updated' })
    );

  const debouncedChangeGraspStatus = debounce(changeGraspStatus, 750);

  const handleChange = (e) => {
    setStatusOptionValue(e.currentTarget.value);
    debouncedChangeGraspStatus(e.currentTarget.value);
  };

  const renderOptions = () => {
    if (status === 'scrapped') {
      return (
        <option key={'scrapped'} value={'scrapped'}>
          {intl.formatMessage({
            id: `GraspInventory.table.statusOptions.scrapped`,
          })}
        </option>
      );
    }

    return statusOptions
      .filter((status) => status !== 'scrapped')
      .map((option) => (
        <option key={option} value={option}>
          {intl.formatMessage({
            id: `GraspInventory.table.statusOptions.${option}`,
          })}
        </option>
      ));
  };

  return (
    <td className="text-center">
      <InputCustom
        noMargin
        style={{ width: '150px' }}
        onChange={handleChange}
        value={statusOptionValue}
        type="select"
        disabled={!organizationId || disabled}
        options={renderOptions()}
      />
    </td>
  );
};

const mapDispatchToProps = {
  displayNotification: displayNotificationAction,
};

export default connect(null, mapDispatchToProps)(injectIntl(Status));
