import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

/**
 * Renders table paginated with infinite scroll
 * @param items - array of items to display
 * @param pagination - object with pagination details
 * @param tableHeader - function returning table header component
 * @param headerProps - object with props injected to table header component
 * @param tableRow - returning table row component
 * @param rowProps - object with props injected to table row component
 * @param fetchMore - function fetching next chunk of items
 * @param hasMore - determines if there are more items to fetch
 * @param isTable - determines if component should be rendered as a Table
 */
const InfiniteTable = ({
  items,
  pagination,
  tableHeader: TableHeader = Fragment,
  headerProps = {},
  tableRow: TableRow,
  rowProps = {},
  fetchMore,
  hasMore,
  isTable = true,
  ...rest
}) => (
  <InfiniteScroll
    dataLength={items.length}
    next={fetchMore}
    hasMore={hasMore === undefined ? pagination.pages_count > pagination.current_page : hasMore}
    loader={<div className="text-center"><FontAwesomeIcon icon={faSpinner} spin /></div>}
    {...rest}
  >
    {isTable ? (
      <Table>
        <TableHeader {...headerProps} />
        <tbody>
          {items.map(itemProps => (
            <TableRow
              key={JSON.stringify(itemProps)}
              {...itemProps}
              {...rowProps}
            />
          ))}
        </tbody>
      </Table>) : (
        <>
          {items.map(itemProps => (
            <TableRow
              key={JSON.stringify(itemProps)}
              {...itemProps}
              {...rowProps}
            />
          ))}
        </>
    )}
  </InfiniteScroll>
);

export default InfiniteTable;
