import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import GraspsPoolTable from './GraspsPoolTable';

const AssignGraspFromPoolModal = ({
  isOpen,
  toggle,
  className,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} className={className} scrollable>
    <ModalHeader toggle={toggle}><FormattedMessage id="EndpointDetails.devicesPool.title" /></ModalHeader>
    <ModalBody style={{ maxHeight: '900px', marginBottom: '1px' }} id="infinite-scroll-parent">
      <GraspsPoolTable />
    </ModalBody>
  </Modal>
);

export default AssignGraspFromPoolModal;
