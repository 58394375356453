import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/**
 * Display navigation links for organization views
 * @param details
 */
const OrganizationNavigation = ({ details }) => {
  const links = ['endpoints', 'users', 'grasps'];

  return (
    <Nav>
      {
        links.map(link => (
          <NavItem key={`navigation-${link}`}>
            <NavLink
              to={`/organization/${details.id}/${link}`}
              tag={Link}
            >
              <FormattedMessage id={`Organization.navigation.${link}`} />
            </NavLink>
          </NavItem>
        ))
      }
    </Nav>
  );
};

export default OrganizationNavigation;
