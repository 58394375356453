import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import InputCustom from '../../../../components/InputCustom';
import request from '../../../../api/request';
import debounce from '../../../../helpers/utils/debounce';

import { displayNotificationAction } from '../../../notifications/actions';

const Numbertag = ({
  displayNotification,
  numbertag,
  graspId,
  organizationId,
  intl,
  disabled,
}) => {
  const changeGraspNumberTag = (value) =>
    request({
      url: `/organizations/${organizationId}/grasps/${graspId}`,
      method: 'PATCH',
      data: {
        numbertag: value,
      },
    }).then(() => displayNotification({ code: 'numbertag_updated' }));

  const debouncedChangeGraspNumberTag = debounce(changeGraspNumberTag, 750);

  const handleChange = (e) => {
    debouncedChangeGraspNumberTag(e.target.value);
  };

  return (
    <td className="text-center">
      <InputCustom
        style={{ width: '150px' }}
        autoComplete="false"
        disabled={!organizationId || disabled}
        onChange={handleChange}
        defaultValue={numbertag}
        placeHolder={
          !numbertag
            ? intl.formatMessage({ id: 'GraspInventory.table.notAssigned' })
            : undefined
        }
      />
    </td>
  );
};

const mapDispatchToProps = {
  displayNotification: displayNotificationAction,
};

export default connect(null, mapDispatchToProps)(injectIntl(Numbertag));
