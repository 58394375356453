import { push } from "react-router-redux";
import { setPassword } from '../../../api/membership';

export const isNewPasswordSetting = () => ({
  type: 'IS_NEW_PASSWORD_SETTING',
});

export const setPasswordSuccess = () => ({
  type: 'SET_NEW_PASSWORD_SUCCESS',
});

export const setFailed = errorMessage => ({
  type: 'SET_NEW_PASSWORD_FAILED',
  errorMessage,
});

export const setPasswordAction = (values, token) => (dispatch) => {
  dispatch(isNewPasswordSetting());

  const arePasswordsEqual = () =>
    values.password !== '' && values.password === values.passwordRepeat;

  if (!arePasswordsEqual()) {
    return dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'passwords_must_match' } });
  } else {
    return setPassword({password: values.password, password_reset_token: token })
      .then(() => {
        dispatch(setPasswordSuccess());
        dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'set_password_success' } });
        dispatch(push('/login'));
      })
      .catch((error) => {
        if (
          error
          && error.data
          && error.data.meta.errors[0]
        ) {
          dispatch(setFailed(''));
          error.data.meta.errors.map(
            singleError => dispatch({type: 'DISPLAY_NOTIFICATION', notification: {code: singleError.code}}),
          );
        } else {
          dispatch(setFailed(''));
          dispatch({type: 'DISPLAY_NOTIFICATION', notification: {code: 'set_password_failed'}});
        }
      });
  }
};
