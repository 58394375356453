const withParams = (APICall, params) => () => APICall(...params);

/**
 * Higher Order Actions function that create async calls to API with redux-thunk
 * @param name - dynamic actions name
 * @param APICall - function that call API
 * @param callback - function that can be called after APICall
 * @param onSuccessNotification - object with code of notification
 */
// eslint-disable-next-line
export const asyncActions = ({
  name,
  APICall,
  callback,
  onSuccessNotification,
}) => (...params) => {
  const thunkAction = withParams(APICall, params);

  return (dispatch) => {
    dispatch({ type: `${name}_STARTED` });

    return dispatch(thunkAction)
      .then(({ data: { data } }) => dispatch({ type: `${name}_SUCCESS`, data }))
      .then((data) => callback && dispatch(callback(data, ...params)))
      .then(() => {
        if (onSuccessNotification) {
          dispatch({
            type: 'DISPLAY_NOTIFICATION',
            notification: onSuccessNotification,
          });
          dispatch({ type: 'CLEAR_NOTIFICATION' });
        }
      })
      .catch((error) => {
        dispatch({ type: `${name}_ERROR`, error });
        if (error.data && error.data.meta && error.data.meta.errors) {
          error.data.meta.errors.map((err) =>
            dispatch({
              type: 'DISPLAY_NOTIFICATION',
              notification: { code: err.code },
            })
          );
        } else if (error.status === 500) {
          dispatch({
            type: 'DISPLAY_NOTIFICATION',
            notification: { code: 500 },
          });
        }
      });
  };
};
