import React from 'react';
import { Input } from 'reactstrap';

const InputCustom = ({
  placeHolder,
  name,
  id,
  type = 'text',
  ...input
}) => (
  <Input
    type={type}
    placeholder={placeHolder}
    name={name}
    id={id}
    {...input}
  />
);

export default InputCustom;
