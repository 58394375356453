import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';

const InputCustom = ({
  id,
  name,
  type = 'text',
  handleChange,
  value,
  label,
  labelProps,
  formGroupProps,
  options,
  noMargin,
  ...rest
}) => (
  <FormGroup style={noMargin && { margin: '0px' }} {...formGroupProps}>
    {label && <Label {...labelProps} for={id}>{label}</Label>}

    <Input
      id={id}
      name={name}
      type={type}
      onChange={handleChange}
      value={value}
      autoComplete="false"
      {...rest}
    >
      {options}
    </Input>
  </FormGroup>
);

export default InputCustom;
