import React from 'react';
import { FormattedMessage } from 'react-intl';

const ExtendedLinkedGraspRow = ({ firmware_version, uptime, hex_color }) => {
  const renderColor = color => (
    <>
      <span style={{ backgroundColor: color }} className="color-details-wrapper" />
      <span className="ml-1 small">({color})</span>
    </>
  );

  return (
    <td colSpan={6} className="border-top-0">
      <p className="mb-1">
        <FormattedMessage id="GraspInventory.table.firmware" />
        {': '}
        {firmware_version || <FormattedMessage id="GraspInventory.table.notAssigned" />}
      </p>
      <p className="mb-1">
        <FormattedMessage id="GraspInventory.table.uptime" />
        {': '}
        {uptime}
      </p>
      <p className="mb-1">
        <FormattedMessage id="GraspInventory.table.color" />
        {': '}
        {hex_color ? renderColor(hex_color) : <FormattedMessage id="GraspInventory.table.notAssigned" />}
      </p>
    </td>
  );
};

export default ExtendedLinkedGraspRow;
