import React from 'react';

const EventTypes = {
  GraspAssignedToCollection: 'GraspAssignedToCollection',
  GraspUnassignedFromCollection: 'GraspUnassignedFromCollection',
  GraspLinkCodeRegenerated: 'GraspLinkCodeRegenerated',
  GraspLinkedToUser: 'GraspLinkedToUser',
  GraspForcedUnlinkFromUser: 'GraspForcedUnlinkFromUser',
  GraspUnlinkedFromUser: 'GraspUnlinkedFromUser',
  GraspObservationSetCreated: 'GraspObservationSetCreated',
  GraspDeleted: 'GraspDeleted',
  GraspObservationSetAutomaticallyAssignedToCollection:
    'GraspObservationSetAutomaticallyAssignedToCollection',
  GraspObservationSetManuallyAssignedToCollection:
    'GraspObservationSetManuallyAssignedToCollection',
  DevicePairCreated: 'DevicePairCreated',
  GraspUpdated: 'GraspUpdated'
};

const EventLogEvent = ({ eventLog, eventType }) => {
  const renderEventText = (log, type) => {
    switch (type) {
      case EventTypes.GraspAssignedToCollection:
        return `Grasp has been assigned to Collection ${log.collection_name}`;

      case EventTypes.GraspUnassignedFromCollection:
        return `Grasp has been unassigned from Collection ${
          log.collection_name
        }`;

      case EventTypes.GraspLinkCodeRegenerated:
        return `Grasp link code has been regenerated`;

      case EventTypes.GraspLinkedToUser:
        return `Grasp has been linked to User`;

      case EventTypes.GraspForcedUnlinkFromUser:
        return `Grasp has been forcefully unlinked from User`;

      case EventTypes.GraspUnlinkedFromUser:
        return `Grasp has been unlinked from User`;

      case EventTypes.GraspObservationSetCreated:
        return `Observation has been added`;

      case EventTypes.GraspObservationSetAutomaticallyAssignedToCollection:
        return `Observations have been set to assign automatically to collection`;

      case EventTypes.GraspObservationSetManuallyAssignedToCollection:
        return `Observations have been set to assign manually to collection`;

      case EventTypes.DevicePairCreated:
        return `Grasp has been paired with device "x"`;

      case EventTypes.GraspUpdated:
        return `Grasp has been updated`;

      case EventTypes.GraspDeleted:
        return `Grasp has been deleted`;

      default:
        return null;
    }
  };

  return <td>{renderEventText(eventLog, eventType)}</td>;
};

export default EventLogEvent;
