const dispatchAction =
  (setIsLoading, successAction, notificationAction, errorAction) => (action, notificationCode, ...params) => () => {
    setIsLoading(true);

    return action(...params)
      .then(() => {
        setIsLoading(false);
        // TODO: add table refresher
        if (successAction) successAction();
        if (notificationCode) notificationAction({ code: notificationCode });
      })
      .catch((e) => {
        if (errorAction) errorAction();
        // eslint-disable-next-line
        console.error(e);
      });
  };

export default dispatchAction;
