/**
 * Function that attach proper params to API call
 * @param APICall - function that call API
 * @param store - function that gets information from currently used module store
 * @param params - additional parameters for API call
 */
export const withParams = (APICall, store, params) => (dispatch, getState) => {
  const filterBy = store(getState).filterBy;
  const sortBy = store(getState).sortBy;
  const query = store(getState).query;
  const currentPage = store(getState).pagination.current_page || 1;
  const pageSize = store(getState).pageSize;

  const reduxParams = {
    page: currentPage,
    filterBy,
    pageSize,
    query,
  };

  if (sortBy) reduxParams.sortBy = sortBy;
  return APICall(reduxParams, ...params);
};

/**
 * Higher Order Action function that create async calls to API with redux-thunk
 * @param name - dynamic actions name
 * @param APICall - function that call API
 * @param getStore - function that gets information from currently used module store
 * @param refresh - determines which pending action should fire (refresh/clear load)
 */
export const withInfinitePaginationAction = (name, APICall, getStore, refresh = false) => (...params) => {
  const thunkAction = withParams(APICall, getStore, params);
  const startActionType = refresh ? `REFRESH_${name}_STORE` : `FETCH_${name}_STARTED`;

  return (dispatch) => {
    dispatch({ type: startActionType });

    return dispatch(thunkAction)
      .then(({ data: { data, meta } }) => {
        dispatch({
          type: `FETCH_${name}_SUCCESS`,
          list: data,
          pagination: meta.pagination,
          meta,
        });
      })
      .catch((error) => {
        dispatch({
          type: `FETCH_${name}_ERROR`,
          error,
        });
      });
  };
};

export const changePageAction = (name, fetchAction) => (page, ...params) => (dispatch) => {
  dispatch({ type: `CHANGE_${name}_PAGE`, page });
  return dispatch(fetchAction(...params));
};

export const clearReducerStoreAction = name => () => dispatch => dispatch({ type: `CLEAR_${name}_STORE` });
