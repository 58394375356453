import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';

// ACTIONS:
import { inviteUser } from '../reducer';

import LoadingButton from '../../../../components/LoadingButton';
import InputCustom from '../../../../components/forms/InputCustom';
import { displayNotificationAction } from '../../../notifications/actions';

const AddOrganizationModal = ({
  isLoading,
  isSuccess,
  error,
  isOpen,
  toggle,
  match,
  inviteUser,
}) => {
  const [inviteEmail, setinviteEmail] = useState('');

  const updateField = ({ target }) => setinviteEmail(target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const model = {
      email: inviteEmail,
      role: 2,
    };
    await inviteUser(match.params.id, model);
    hideModal();
  };
  const hideModal = () => {
    toggle();
    setinviteEmail('');
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="text-center">
        <FormattedMessage id="AddOrganization.inviteNewUser" />
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody className="px-4 pb-4">
          <InputCustom
            required
            id="add-organization-name"
            type="email"
            name="name"
            value={inviteEmail}
            handleChange={updateField}
            label={<FormattedMessage id="ResetPassword.email" />}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="light" onClick={hideModal}>
            <FormattedMessage id="EndpointDetails.settings.cancel" />
          </Button>

          <LoadingButton
            isLoading={isLoading}
            isSucceed={isSuccess}
            isFailed={error}
            callback={toggle}
            value="Organization.invite"
            type="submit"
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  organization: {
    invitationUser: { isLoading, isSuccess, error },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  inviteUser: inviteUser,
  displayNotification: displayNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddOrganizationModal));
