import React from 'react';
import { FormattedMessage } from 'react-intl';
import logo from '../../../../assets/img/logo-horiz-white.png';

const GraspLogo = ({ className }) => (
  <div className={className}>
    <img src={logo} alt="logo" width="240" />
    <h1 className="text-white"><FormattedMessage id="LoginForm.adminPanel" /></h1>
  </div>
);

export default GraspLogo;
