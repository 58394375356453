import React from 'react';
import { PaginationLink } from 'reactstrap';
import ReactPaginate from 'react-paginate';

/**
 * Display page navigation using ReactPaginate
 */
const Pagination = ({
  pagination,
  getData,
}) => {
  // eslint-disable-next-line
  const handlePageClick = ({ selected }) => {
    getData(selected + 1);
  };

  if (pagination && pagination.pages_count > 1) {
    return (
      <ReactPaginate
        disableInitialCallback
        initialPage={pagination.current_page - 1}
        nextLabel={<PaginationLink next />}
        previousLabel={<PaginationLink previous />}
        breakLabel={<PaginationLink> ... </PaginationLink>}
        breakClassName="break-me"
        pageCount={pagination.pages_count}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName="pagination my-4 justify-content-center mx-auto col-11 col-sm-9 col-md-8 col-lg-6 col-xl-4"
        pageClassName="page-item"
        previousClassName="page-item mr-auto"
        nextClassName="page-item ml-auto"
        pageLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
        hrefBuilder={() => ''}
      />
    );
  }
  return null;
};

export default Pagination;
