import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { useDebounce } from '../../../helpers/hooks/useDebounce';

const SearchBar = ({ changeQuery, query }) => {
  const [searchValue, setSearchValue] = useState(query);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleChange = ({ target: { value } }) => setSearchValue(value);

  useEffect(() => {
    changeQuery(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchInputID = 'grasp-inventory-search-query';

  return (
    <Form
      inline
      className="d-inline-flex mb-2 mb-lg-0"
      style={{ paddingRight: '13px' }}
      onSubmit={(e) => e.preventDefault()}
    >
      <FormGroup className="d-flex align-items-center mb-2 mb-lg-0">
        <Label htmlFor={searchInputID} className="mr-2 mb-0">
          <FormattedMessage id="GraspInventory.search" />:
        </Label>
        <Input value={searchValue} onChange={handleChange} id={searchInputID} />
      </FormGroup>
    </Form>
  );
};

export default SearchBar;
