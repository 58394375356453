import React from 'react';

// COMPONENTS:
import SortableColumn from '../../../components/Table/SortableColumnHeader';

const InventoryHeader = ({
  changeSortBy,
  sortBy,
  headers,
}) => (
  <thead>
    <tr>
      {
        headers.map(({ columnName, translationId, ...rest }) => (
          <SortableColumn
            key={columnName + translationId}
            columnName={columnName}
            sortBy={sortBy}
            changeSortBy={changeSortBy}
            translationId={translationId}
            {...rest}
          />
        ))
      }
    </tr>
  </thead>
);

export default InventoryHeader;
