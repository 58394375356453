// TYPES:
export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

// ACTIONS:
export const displayNotificationAction = (notification) => {
  return {
    type: DISPLAY_NOTIFICATION,
    notification,
  };
};

export const dismissNotificationAction = (notificationId) => ({
  type: DISMISS_NOTIFICATION,
  notificationId,
});

export const clearNotificationAction = () => ({
  type: CLEAR_NOTIFICATION,
});
