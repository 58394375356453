import React from 'react';

/**
 * Renders grasp-like shape
 * @param {string} color - filling color of grasp device
 */
const graspBlob = color => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="rotate(0.2731760551354715,17.5,17.5)">
      <path
        stroke="black"
        strokeWidth="0.1"
        fill={color}
        // eslint-disable-next-line
        d="M17.5,6.226670123835643C20.582653330577237,6.575369582623868,21.785295485889215,10.120402817138729,23.58541598005906,12.647042695623163C25.18622428444754,14.893929008387598,27.282310057567873,16.936977040846262,27.091454934201778,19.68918699863876C26.862774400981717,22.9868550390942,25.458497241141888,26.410910250800573,22.52525219730488,27.935043710875725C19.51553495177788,29.498912631036703,15.761775935490466,28.876094645564322,12.89283062561278,27.066885783547853C10.355693826621668,25.466921376739936,9.67823718698044,22.361742890714652,9.03993049303434,19.430955657852007C8.413330851814424,16.553921395136406,7.8144048128411425,13.522015994983427,9.402878941151391,11.042761429008923C11.185296757858039,8.26080393169351,14.216954579205414,5.855302980455344,17.5,6.226670123835643"
      />
    </g>
  </svg>
);

export default graspBlob;
