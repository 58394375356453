import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import Table from '../../../../../components/Table';
import Pagination from '../../../../../components/Pagination';
import UnlinkedGraspsHeader from './UnlinkedGraspsHeader';
import UnlinkedGraspsRow from './UnlinkedGraspsRow';

// ACTIONS:
import { fetchUnlinkedGraspsAction, changeUnlinkedGraspsPageAction, clearUnlinkedGraspsStoreAction } from './reducer';

const UnlinkedGraspsTable = ({
  isLoading,
  error,
  unlinkedGrasps,
  pagination,
  match,
  fetchUnlinkedGrasps,
  changeUnlinkedGraspsPage,
  clearUnlinkedGraspsStore,
}) => {
  const { id, endpointId } = match.params;
  const getUnlinkedGrasps = () => {
    fetchUnlinkedGrasps(id, endpointId);
  };

  const changePage = page => changeUnlinkedGraspsPage(page, id, endpointId);

  useEffect(
    () => {
      getUnlinkedGrasps();
      return () => clearUnlinkedGraspsStore();
    },
    [],
  );
  const EmptyListComponent = () => <FormattedMessage id="EndpointDetails.unlinkedList.emptyList" />;

  return (
    <>
      <Table
        list={unlinkedGrasps}
        isLoading={isLoading}
        isFailed={error}
        tableRow={UnlinkedGraspsRow}
        tableHeader={UnlinkedGraspsHeader}
        emptyList={EmptyListComponent}
      />
      <Pagination
        pagination={pagination}
        getData={changePage}
      />
    </>
  );
};

const mapStateToProps = ({
  organization: {
    endpoint: {
      unlinkedGrasps: {
        isLoading,
        isSuccess,
        error,
        list,
        pagination,
      },
    },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
  unlinkedGrasps: list,
  pagination,
});

const mapDispatchToProps = {
  fetchUnlinkedGrasps: fetchUnlinkedGraspsAction,
  changeUnlinkedGraspsPage: changeUnlinkedGraspsPageAction,
  clearUnlinkedGraspsStore: clearUnlinkedGraspsStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UnlinkedGraspsTable));
