import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

const LoadingSpinner = ({
  isLoading = true,
  icon,
  size,
  className,
  ...rest
}) => (
  <>
    {
      isLoading && (
        <div className={className}>
          <FontAwesomeIcon
            icon={icon || faSpinner}
            size={size || '1x'}
            {...rest}
            spin
          />
        </div>
      )
    }
  </>
);

export default LoadingSpinner;
