import React from 'react';
import { FormattedDate } from 'react-intl';

const EventLogDate = ({ date }) => (
  <td>
    {<FormattedDate value={date} year="numeric" month="short" day="2-digit" />}
  </td>
);

export default EventLogDate;
