import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

// COMPONENTS
import ActionsDetailsModal from './ActionsDetailsModal';

const GraspTableHeader = () => {
  const [isActionDetailsModalOpen, setActionDetail] = useState(false);
  const toggleActionDetailsModal = () => setActionDetail(!isActionDetailsModalOpen);

  return (
    <>
      <thead>
        <tr>
          <th style={{ width: '45%' }}>
            <FormattedMessage id="OrganizationGrasps.headers.uuid" />
          </th>
          <th style={{ width: '20%' }}>
            <FormattedMessage id="OrganizationGrasps.headers.endpoint" />
          </th>
          <th style={{ width: '20%' }}>
            <FormattedMessage id="OrganizationGrasps.headers.linkStatus" />
          </th>
          <th style={{ width: '15%' }}>
            <div className="d-flex align-items-center justify-content-end px-2">
              <FormattedMessage id="OrganizationGrasps.headers.actions" />
              <Button
                color="link"
                className="p-0 m-0 pl-1"
                onClick={toggleActionDetailsModal}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Button>
            </div>
          </th>
        </tr>
      </thead>

      {
        isActionDetailsModalOpen &&
        <ActionsDetailsModal
          isOpen={isActionDetailsModalOpen}
          toggle={toggleActionDetailsModal}
        />
      }
    </>
  );
};

export default GraspTableHeader;
