import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import AddEndpointModal from './AddEndpointModal';

/**
 * Display endpoints count in organization dashboard view
 */
const EndpointCard = ({ endpointsCount = 0, organizationId, addEndpoint }) => {
  const [isAddEndpointModalOpen, updateAddEndpointModalState] = useState(false);

  const toggleModal = () =>
    updateAddEndpointModalState(!isAddEndpointModalOpen);

  return (
    <>
      <Card className="organization-card organization-card--count">
        <CardBody>
          <div className="d-flex justify-content-between">
            <CardTitle className="mb-0">
              <FormattedMessage id="Organization.cards.endpointsCount" />
            </CardTitle>

            <Button size="sm" color="primary" onClick={toggleModal}>
              {/* TODO: Add font-awesome icon */}
              Add
            </Button>
          </div>

          <h2 className="text-center">{endpointsCount}</h2>

          <Button
            className="p-0 m-0"
            color="link"
            tag={Link}
            to={`/organization/${organizationId}/endpoints`}
          >
            <FormattedMessage id="Organization.cards.viewAll" />
          </Button>
        </CardBody>
      </Card>

      {isAddEndpointModalOpen && (
        <AddEndpointModal
          isOpen={isAddEndpointModalOpen}
          toggle={toggleModal}
          addEndpoint={addEndpoint}
        />
      )}
    </>
  );
};

export default EndpointCard;
