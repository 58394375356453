import React from 'react';
import { Switch } from 'react-router-dom';

// LAYOUTS:
import AdminNavigation from './components/Header';
import OrganizationLayout from './components/layouts/OrganizationLayout';

// ROUTES:
import PrivateRoute from './components/routes/PrivateRoute';
import MembershipRoute from './components/routes/MembershipRoute';

// COMPONENTS:
import AdminLogin from './modules/membership/login';
import PasswordReset from './modules/membership/password-reset';
import ResendEmail from './modules/membership/resend-email';
import ConfirmEmail from './modules/membership/confirm-email';
import SetPassword from './modules/membership/set-password';
import Register from './modules/membership/register';
import Dashboard from './modules/dashboard';
import Organization from './modules/organization/dashboard';
import OrganizationUsersList from './modules/organization/users';
import OrganizationGrasps from './modules/organization/grasps';
import OrganizationEndpoints from './modules/organization/endpoints';
import EndpointsDetails from './modules/organization/endpoint';
import GraspInventory from './modules/graspInventory';
import Notification from './modules/notifications';
import EventLogs from './modules/eventLogs';

const App = () => (
  <>
    <Notification />
    <Switch>
      {/* LOGIN */}
      <MembershipRoute exact path="/login" component={AdminLogin} />

      {/* REGISTER */}
      <MembershipRoute exact path="/register" component={Register} />

      {/* CONFIRM EMAIL */}
      <MembershipRoute
        exact
        path="/email-confirmation/:id/:code"
        component={ConfirmEmail}
      />

      {/* RESEND EMAIL */}
      <MembershipRoute exact path="/resend-email" component={ResendEmail} />

      {/* RESET PASSWORD */}
      <MembershipRoute exact path="/password-reset" component={PasswordReset} />

      {/* SET PASSWORD */}
      <MembershipRoute
        exact
        path="/set-password/:token"
        component={SetPassword}
      />

      {/* DASHBOARD */}
      <PrivateRoute
        exact
        path="/"
        component={Dashboard}
        layout={AdminNavigation}
      />

      {/* ORGANIZATION */}
      <PrivateRoute
        exact
        path="/organization/:id"
        component={Organization}
        layout={OrganizationLayout}
      />

      <PrivateRoute
        exact
        path="/organization/:id/endpoints"
        component={OrganizationEndpoints}
        layout={OrganizationLayout}
      />

      <PrivateRoute
        exact
        path="/organization/:id/endpoints/:endpointId"
        component={EndpointsDetails}
        layout={OrganizationLayout}
      />

      <PrivateRoute
        exact
        path="/organization/:id/users"
        component={OrganizationUsersList}
        layout={OrganizationLayout}
      />

      <PrivateRoute
        path="/organization/:id/grasps"
        component={OrganizationGrasps}
        layout={OrganizationLayout}
      />

      {/* EVENT LOG */}
      <PrivateRoute
        path="/organization/:id/event-logs/:graspId"
        component={EventLogs}
        layout={AdminNavigation}
      />

      {/* GRASPS INVENTORY */}
      <PrivateRoute
        exact
        path="/grasp-inventory"
        component={GraspInventory}
        layout={AdminNavigation}
      />
    </Switch>
  </>
);

export default App;
