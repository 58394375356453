import { getEndpointDetails, updateEndpoint } from '../../../api/organizations';
import fetchAsyncWithDataReducers from '../../../helpers/HOR/asyncActionWithData/reducer';
import {
  fetchAsyncActionsWithData,
  clearReducerStoreAction,
} from '../../../helpers/HOR/asyncActionWithData/actions';
import { asyncActions } from '../../../helpers/HOR/asyncAction/actions';
import asyncReducers from '../../../helpers/HOR/asyncAction/reducer';

// HELPERS:
const ENDPOINT = 'ENDPOINT';
const EDIT_ENDPOINT = 'EDIT_ENDPOINT';

// REDUCER:
export const endpointDetailsReducer = fetchAsyncWithDataReducers(ENDPOINT);
export const editEndpointReducer = asyncReducers(EDIT_ENDPOINT);

// ACTIONS:
export const fetchEndpointDetailsAction = fetchAsyncActionsWithData(
  ENDPOINT,
  getEndpointDetails
);

export const clearEndpointDetailsStoreAction = clearReducerStoreAction(
  ENDPOINT
);

const editEndpointAsyncActionParameters = {
  name: EDIT_ENDPOINT,
  APICall: updateEndpoint,
  callback: (data, organizationId, endpointId) =>
    fetchEndpointDetailsAction(organizationId, endpointId),
  onSuccessNotification: { code: 'endpoint_updated' },
};

export const editEndpointAction = asyncActions(
  editEndpointAsyncActionParameters
);
