const initialState = {
  isResending: false,
  resendFailed: false,
  resendSuccess: false,
  resendError: [],
};

const resendEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_EMAIL_RESENDING':
      return {
        ...state,
        isResending: true,
        resendFailed: false,
        resendSuccess: false,
      };

    case 'RESEND_EMAIL_SUCCESS':
      return {
        ...state,
        resendSuccess: true,
        isResending: false,
      };

    case 'RESEND_EMAIL_FAILED':
      return {
        ...state,
        resendError: action.errorMessage,
        resendFailed: true,
        isResending: false,
      };

    default:
      return state;
  }
};

export default resendEmailReducer;
