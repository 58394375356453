import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// COMPONENTS:
import GraspName from '../../../../../components/GraspName';

// ACTIONS:
import { assignGraspToEndpointAction } from './reducer';

const GraspPoolRow = ({
  id,
  name,
  physical_grasp_uuid,
  match,
  assignGraspToEndpoint,
}) => {
  const handleAction = () => {
    assignGraspToEndpoint(match.params.id, match.params.endpointId, { grasp_id: id });
  };

  return (
    <tr>
      <td>
        <GraspName name={name} uuid={physical_grasp_uuid} />
      </td>

      <td className="text-right pr-3">
        <Button
          color="primary"
          size="sm"
          onClick={handleAction}
        >
          <FormattedMessage id="OrganizationGrasps.actions.assign" />
        </Button>
      </td>
    </tr>
  );
};

const mapDispatchToProps = {
  assignGraspToEndpoint: assignGraspToEndpointAction,
};

export default connect(null, mapDispatchToProps)(withRouter(GraspPoolRow));
