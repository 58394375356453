import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// ACTIONS:
import { fetchGraspInventory } from '../../../reducer';
import request from '../../../../../api/request';
import LoadingButton from '../../../../../components/LoadingButton';

const ScrapModal = ({
  isOpen,
  toggle,
  className,
  name,
  serialNumber,
  organizationId,
  graspId,
  getGraspInventory,
}) => {
  const [isScrapping, setIsScrapping] = useState(false);
  const scrapGrasp = () => {
    setIsScrapping(true);
    return request({
      url: `/organizations/${organizationId}/grasps/${graspId}`,
      method: 'PATCH',
      data: {
        status: 'scrapped',
      },
    })
      .then(() => {
        toggle();
        getGraspInventory();
      })
      .finally(() => setIsScrapping(false));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} scrollable>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="GraspInventory.modals.scrapModal.header" />
      </ModalHeader>
      <ModalBody>
        <h5>
          <FormattedMessage id="GraspInventory.modals.scrapModal.concerns" />:
        </h5>
        <div>
          <FormattedMessage id="GraspInventory.modals.scrapModal.name" />:{' '}
          {name}
        </div>
        <div>
          <FormattedMessage id="GraspInventory.modals.scrapModal.serialNumber" />
          : {serialNumber}
        </div>
        <h5 className="mt-2">
          <FormattedMessage id="GraspInventory.modals.scrapModal.note" />:
        </h5>
        <div>
          <FormattedMessage id="GraspInventory.modals.scrapModal.warning1" />
        </div>
        <div>
          <FormattedMessage id="GraspInventory.modals.scrapModal.warning2" />
        </div>
      </ModalBody>
      <ModalFooter>
        <LoadingButton
          isLoading={isScrapping}
          color="primary"
          onClick={scrapGrasp}
        >
          Yes, scrap this Grasp
        </LoadingButton>
        <Button color="danger" onClick={toggle}>
          No, go back
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = {
  getGraspInventory: fetchGraspInventory,
};

export default connect(null, mapDispatchToProps)(ScrapModal);
