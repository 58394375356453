import React, { useState } from 'react';
import { connect } from "react-redux";

// COMPONENTS:
import ResendEmailForm from "./components/ResendEmailForm";
import MembershipHeader from "../MembershipHeader";
import { resendEmailAction } from "../resend-email/actions";

const ResendEmail = ({ isLoading, isSuccess, resendFailed, resendEmail }) => {
  const [values, setValues] = useState({ email: ''});

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setValues({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resendEmail(values.email);
  };

  return (
    <>
      <MembershipHeader id="ResendEmail.header" />
      <ResendEmailForm
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        resendFailed={resendFailed}
      />
    </>
  );
};

const mapStateToProps = ({ membership: { resendEmail } }) => ({
  isLoading: resendEmail.isResending,
  isSucceed: resendEmail.resendSuccess,
  resendFailed: resendEmail.resendFailed,
});

const mapDispatchToProps = {
  resendEmail: resendEmailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmail);
