import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const EventLog = ({ disabled, redirect, href }) => {
  return (
    <td>
      <Button
        disabled={disabled}
        color="info"
        size="sm"
        onClick={redirect}
        href={href}
      >
        <FormattedMessage id="GraspInventory.table.eventLog" />
      </Button>
    </td>
  );
};

export default EventLog;
