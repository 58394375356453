import { getEndpoints, createEndpoint } from '../../../api/organizations';
import { changePageAction, withPaginationActions } from '../../../helpers/HOR/withPagination/actions';
import withPaginationReducers, { initialState } from '../../../helpers/HOR/withPagination/reducer';
import { asyncActions } from '../../../helpers/HOR/asyncAction/actions';

// HELPERS:
const MODULE_NAME = 'ENDPOINTS';
const endpointsInitialState = { ...initialState, pageSize: 12 };
const endpointsStore = getState => getState().organization.endpoints;

const ADD_ENDPOINT = 'ADD_ENDPOINT';

// REDUCER:
export const endpointsReducer = withPaginationReducers(MODULE_NAME, endpointsInitialState);

// ACTIONS:
export const fetchEndpointsAction = withPaginationActions(
  MODULE_NAME,
  getEndpoints,
  endpointsStore,
);

export const changeEndpointsPageAction = changePageAction(MODULE_NAME, fetchEndpointsAction);

const addEndpointAsyncActionParameters = {
  name: ADD_ENDPOINT,
  APICall: createEndpoint,
  callback: (data, organizationId) => fetchEndpointsAction(organizationId),
  onSuccessNotification: { code: 'endpoint_added' },
};

export const addEndpointAction = asyncActions(addEndpointAsyncActionParameters);
