import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// ACTIONS:
import { fetchEndpointDetailsAction, clearEndpointDetailsStoreAction } from './reducer';

// COMPONENTS:
import Header from './components/Header';
import UnlinkedGraspsTable from './components/UnlinkedGraspsTable';
import LinkedGraspsTable from './components/LinkedGraspsTable';
import TableTitle from './components/TableTitle';
import LoadingSpinner from '../../../components/LoadingSpinner';

const EndpointsDetails = ({
  match,
  data,
  isLoading,
  isSuccess,
  error,
  fetchEndpoint,
  clearEndpointDetailsStore,
}) => {
  useEffect(() => {
    fetchEndpoint(match.params.id, match.params.endpointId);
    return () => clearEndpointDetailsStore();
  }, []);

  switch (true) {
    case isLoading:
      return <LoadingSpinner className="text-center" size="2x" />;

    case error:
      return <div>failed</div>;

    case isSuccess:
      return (
        <Container>
          <Header name={data.name} />
          <Row>
            <Col xs={12} lg={7}>
              <TableTitle
                titleId="EndpointDetails.linkedList.title"
                subTitleId="EndpointDetails.linkedList.subTitle"
              />
            </Col>
            <Col xs={12} lg={5} className="d-none d-lg-block">
              <TableTitle
                titleId="EndpointDetails.unlinkedList.title"
                subTitleId="EndpointDetails.unlinkedList.subTitle"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={7}>
              <LinkedGraspsTable />
            </Col>

            <Col xs={12} className="my-5 d-lg-none">
              <hr />
            </Col>
            <Col xs={12} lg={5} className="d-lg-none">
              <TableTitle
                titleId="EndpointDetails.unlinkedList.title"
                subTitleId="EndpointDetails.unlinkedList.subTitle"
              />
            </Col>
            <Col xs={12} lg={5} className="mb-5">
              <UnlinkedGraspsTable />
            </Col>
          </Row>
        </Container>
      );

    default:
      return null;
  }
};

const mapStateToProps = ({
  organization: {
    endpoint: {
      details: {
        data,
        isLoading,
        isSuccess,
        error,
      },
    },
  },
}) => ({
  data,
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  fetchEndpoint: fetchEndpointDetailsAction,
  clearEndpointDetailsStore: clearEndpointDetailsStoreAction,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EndpointsDetails));
