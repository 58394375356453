import React from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

// COMPONENTS:
import GraspName from '../../../../../components/GraspName';

const LinkedGraspsRow = ({
  name,
  physical_grasp_uuid,
  created_at,
  battery_level,
  toggleExpand,
  isRowExpanded,
}) => {
  const handleAction = () => {
    toggleExpand();
  };

  return (
    <tr>
      <td>
        <GraspName name={name} uuid={physical_grasp_uuid} />
      </td>

      <td>
        {moment(created_at).format('L') || '-'}
      </td>

      <td className="px-4">
        {`${battery_level}%`}
      </td>

      <td>
        <Button
          color="primary"
          size="sm"
          onClick={handleAction}
        >
          {
            isRowExpanded ?
              <FormattedMessage id="OrganizationGrasps.actions.hide" /> :
              <FormattedMessage id="OrganizationGrasps.actions.show" />
          }
        </Button>
      </td>
    </tr>
  );
};

export default LinkedGraspsRow;
