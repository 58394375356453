import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-solid-svg-icons';

// COMPONENTS:
import OrganizationSettings from './OrganizationSettings';

const Header = ({ name, logoUrl }) => {
  const [settingsModal, setSettingsModal] = useState(false);
  const settingsModalToggle = () => setSettingsModal(!settingsModal);

  return (
    <Row>
      <Col xs={12} sm={6}>
        <div className="d-flex align-items-center">
          <h1>
            <FormattedMessage id="Organization.dashboard" />
          </h1>
          <div
            style={{ cursor: 'pointer' }}
            className="ml-2 text-primary"
            onClick={() => setSettingsModal(true)}
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </div>
        </div>
      </Col>
      <OrganizationSettings
        name={name}
        logoUrl={logoUrl}
        isOpen={settingsModal}
        toggle={settingsModalToggle}
      />
    </Row>
  );
};

export default Header;
