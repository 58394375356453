import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';

// COMPONENTS:
import Image from '../../Image';

/**
 * Display organization logo and name in Organization navigation bar
 * @param details - organization info
 */
const OrganizationLogo = ({ details }) => (
  <NavbarBrand
    className="d-flex align-items-center text-decoration-none"
    tag={Link}
    to={`/organization/${details.id}`}
  >
    {
      details.logo_url &&
      <Image
        src={details.logo_url}
        alt={`${details.name} logo`}
        style={{ height: 40 }}
      />
    }

    <h5 className="mb-0 ml-3">
      {details.name}
    </h5>
  </NavbarBrand>
);


export default OrganizationLogo;
