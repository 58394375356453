import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

// CHECKING FUNCTIONS:
import { isAuthenticated } from '../../helpers/authentication';

const PrivateRoute = ({
  component: Component,
  layout: Layout = Fragment,
  authentication = isAuthenticated,
  redirectTo = '/login',
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      authentication()
        ? (
          <Layout>
            <Component {...props} />
          </Layout>
        )
        : <Redirect to={redirectTo} />
    )}
  />
);

export default PrivateRoute;
