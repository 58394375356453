import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

// ACTIONS:
import {
  fetchGraspInventory,
  changeInventoryPageAction,
  clearStoreAction,
  changeInventoryQueryAction,
  changeInventoryFilterByAction,
  changeInventorySortByAction
} from './reducer';

// COMPONENTS:
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import InventoryHeader from './components/InventoryHeader';
import InventoryExtendableRow from './components/InventoryExtendableRow';
import SearchBar from './components/SearchBar';
import Filters from '../../components/Filters';

// HELPERS:
const filtersList = [
  'All',
  'OnlyUnassignedToOrganization',
  'OnlyAssignedToOrganization'
];

const headers = [
  {
    columnName: 'GraspName',
    translationId: 'GraspInventory.table.graspName',
    colWidth: '350px'
  },
  {
    columnName: 'Creation',
    translationId: 'GraspInventory.table.createdAt',
    colWidth: '120px'
  },
  {
    columnName: 'Update',
    translationId: 'GraspInventory.table.updatedAt',
    colWidth: ''
  },
  {
    columnName: 'OrganizationName',
    translationId: 'GraspInventory.table.organization',
    colWidth: '150px'
  },
  {
    columnName: 'EndpointName',
    translationId: 'GraspInventory.table.endpoint',
    colWidth: '180px'
  },
  {
    columnName: 'CreatedBy',
    translationId: 'GraspInventory.table.createdBy',
    colWidth: '240px'
  },
  {
    columnName: 'Owner',
    translationId: 'GraspInventory.table.owner',
    colWidth: '150px'
  },
  {
    columnName: 'Status',
    translationId: 'GraspInventory.table.status',
    colWidth: '180px'
  },
  {
    columnName: 'SerialNumber',
    translationId: 'GraspInventory.table.serialNumber',
    colWidth: '180px'
  },
  {
    columnName: 'Numbertag',
    translationId: 'GraspInventory.table.numbertag',
    colWidth: '180px'
  },
  {
    columnName: 'Flashdate',
    translationId: 'GraspInventory.table.flashdate',
    colWidth: '180px'
  },
  {
    columnName: 'Event log',
    translationId: 'GraspInventory.table.eventLog',
    colWidth: '110px'
  },
  {
    columnName: 'Actions',
    translationId: 'GraspInventory.table.actions',
    colWidth: '160px',
    disabled: true
  }
];

const GraspInventory = ({
  getGraspInventories,
  changeInventoryPage,
  clearInventoryStore,
  changeInventoryQuery,
  changeInventoryFilterBy,
  changeInventorySortBy,
  list,
  pagination,
  isLoading,
  query,
  filterBy,
  sortBy,
  error,
  meta
}) => {
  useEffect(() => {
    getGraspInventories();
    return clearInventoryStore;
  }, []);

  const changePage = page => changeInventoryPage(page);
  const changeQuery = queryParam => changeInventoryQuery(queryParam);
  const changeFilterBy = filter => changeInventoryFilterBy(filter);
  const changeSortBy = sort => changeInventorySortBy(sort);

  return (
    <Fragment>
      <Container fluid className="px-5">
        <Row className="mb-4">
          <Col xs={12} lg={4}>
            <h2>
              <FormattedMessage id="GraspInventory.title" />
            </h2>
            <p>
              <FormattedMessage id="GraspInventory.subTitle" />
            </p>
          </Col>

          <Col xs={12} lg={8} className="text-md-right">
            <SearchBar query={query} changeQuery={changeQuery} />
            <Filters
              filters={filtersList}
              filterBy={filterBy}
              changeFilter={changeFilterBy}
            />
          </Col>
        </Row>

        <Row style={{ overflowX: 'auto' }}>
          <Col>
            <Table
              className="grasp-inventory-table"
              list={list}
              isLoading={isLoading}
              isFailed={!!error}
              tableHeader={InventoryHeader}
              tableRow={InventoryExtendableRow}
              headerProps={{ changeSortBy, sortBy, headers }}
              rowProps={{ meta }}
            />
          </Col>
        </Row>

        <Row className="mt-4 mb-5">
          <Col>
            <Pagination pagination={pagination} getData={changePage} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({
  inventory: {
    grasps: {
      list,
      pagination,
      isLoading,
      error,
      query,
      filterBy,
      sortBy,
      meta
    }
  }
}) => ({
  list,
  pagination,
  isLoading,
  error,
  query,
  filterBy,
  sortBy,
  meta
});

const mapDispatchToProps = {
  getGraspInventories: fetchGraspInventory,
  changeInventoryPage: changeInventoryPageAction,
  clearInventoryStore: clearStoreAction,
  changeInventoryQuery: changeInventoryQueryAction,
  changeInventoryFilterBy: changeInventoryFilterByAction,
  changeInventorySortBy: changeInventorySortByAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraspInventory);
