import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Navbar } from 'reactstrap';

// COMPONENTS:
import Header from '../../Header';
import OrganizationLogo from './OrganizationLogo';
import OrganizationNavigation from './OrganizationNavigation';

// ACTIONS:
import { fetchOrganizationAction } from '../../../modules/organization/dashboard/reducer';

// HELPERS:
const navbarStyles = {
  height: '67px',
  top: '56px',
  backgroundColor: '#fff',
  zIndex: 100,
};

/**
 * Administration layout with additional organization navigation bar
 */
const OrganizationLayout = ({
  children,
  details,
  fetchOrganization,
  match,
}) => {
  useEffect(
    () => {
      const { id } = match.params;
      fetchOrganization(id);
    },
    [],
  );

  return (
    <Header removeHeaderGap>
      <>
        <Navbar
          className="border-bottom mb-5"
          style={navbarStyles}
          sticky="top"
        >
          {
            details && (
              <>
                <OrganizationLogo details={details} />
                <OrganizationNavigation details={details} />
              </>
            )}
        </Navbar>
        {children}
      </>
    </Header>
  );
};

const mapStateToProps = ({
  organization: {
    details: {
      data,
    },
  },
}) => ({
  details: data,
});

const mapDispatchToProps = {
  fetchOrganization: fetchOrganizationAction,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationLayout));
