/**
 * Function that attach proper params to API call
 * @param APICall - function that call API
 * @param store - function that gets information from currently used module store
 * @param params - additional parameters for API call
 * @param forceCurrentPage - parameter to force fetching data from passed page value
 */
export const withParams = (APICall, store, params, forceCurrentPage) => (
  dispatch,
  getState
) => {
  const filterBy = store(getState).filterBy;
  const sortBy = store(getState).sortBy;
  const query = store(getState).query;
  let currentPage = store(getState).pagination.current_page || 1;
  const pageSize = store(getState).pageSize;

  if (forceCurrentPage) {
    currentPage = forceCurrentPage;
  }

  const reduxParams = {
    page: currentPage,
    filterBy,
    pageSize,
    query
  };

  if (sortBy) reduxParams.sortBy = sortBy;

  return APICall(reduxParams, ...params);
};

/**
 * Higher Order Action function that create async calls to API with redux-thunk
 * @param name - dynamic actions name
 * @param APICall - function that call API
 * @param getStore - function that gets information from currently used module store
 * @param refresh - determines which pending action should fire (refresh/clear load)
 */
export const withPaginationActions = (
  name,
  APICall,
  getStore,
  refresh = false
) => (...params) => {
  const thunkAction = withParams(APICall, getStore, params);
  const startActionType = refresh
    ? `REFRESH_${name}_STORE`
    : `FETCH_${name}_STARTED`;

  return dispatch => {
    dispatch({ type: startActionType });

    return dispatch(thunkAction)
      .then(({ data: { data, meta } }) => {
        if (
          meta.pagination.pages_count !== 0 &&
          meta.pagination.current_page > meta.pagination.pages_count
        ) {
          dispatch(
            withParams(APICall, getStore, params, meta.pagination.pages_count)
          ).then(res => {
            dispatch({
              type: `FETCH_${name}_SUCCESS`,
              list: res.data.data,
              pagination: res.data.meta.pagination,
              meta: res.data.meta
            });
          });
        } else {
          dispatch({
            type: `FETCH_${name}_SUCCESS`,
            list: data,
            pagination: meta.pagination,
            meta
          });
        }
      })
      .catch(error => {
        dispatch({
          type: `FETCH_${name}_ERROR`,
          error
        });
      });
  };
};

export const changePageAction = (name, fetchAction) => (
  page,
  ...params
) => dispatch => {
  dispatch({ type: `CHANGE_${name}_PAGE`, page });
  return dispatch(fetchAction(...params));
};

export const changeSearchQueryAction = (name, fetchAction) => (
  query,
  ...params
) => dispatch => {
  dispatch({ type: `CHANGE_${name}_SEARCH_QUERY`, query });
  return dispatch(fetchAction(...params));
};

export const changeFilterByAction = (name, fetchAction) => (
  filterBy,
  ...params
) => dispatch => {
  dispatch({ type: `UPDATE_${name}_FILTER_BY`, filterBy });
  return dispatch(fetchAction(...params));
};

export const changeSortByAction = (name, fetchAction) => (
  sortBy,
  ...params
) => dispatch => {
  dispatch({ type: `UPDATE_${name}_SORT_BY`, sortBy });
  return dispatch(fetchAction(...params));
};

export const clearReducerStoreAction = name => () => dispatch =>
  dispatch({ type: `CLEAR_${name}_STORE` });
