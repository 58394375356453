import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// COMPONENTS:
import MembershipLayout from '../layouts/MembershipLayout';

// CHECKING FUNCTIONS:
import { isAuthenticated } from '../../helpers/authentication';

const MembershipRoute = ({
  component: Component,
  layout: Layout = MembershipLayout,
  authentication = isAuthenticated,
  redirectTo = '/',
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      !authentication()
        ? (
          <Layout>
            <Component {...props} />
          </Layout>
        )
        : <Redirect to={redirectTo} />
    )}
  />
);

export default MembershipRoute;
