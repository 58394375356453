import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { editOrganizationAction } from '../reducer';

// COMPONENTS:
import InputCustom from '../../../../components/forms/InputCustom';
import LoadingButton from '../../../../components/LoadingButton';

const OrganizationSettings = ({
  isOpen,
  toggle,
  className,
  name,
  logoUrl,
  editOrganization,
  isLoading,
  isSuccess,
  error,
  match,
}) => {
  const { id } = match.params;
  const [organizationName, setOrganizationName] = useState(name);
  const [organizationLogoUrl, setOrganizationLogoUrl] = useState(logoUrl);

  const handleNameChange = ({ target }) => setOrganizationName(target.value);
  const handleUrlChange = ({ target }) => setOrganizationLogoUrl(target.value);

  const handleUpdateOrganization = () => {
    if (organizationName === name && organizationLogoUrl === logoUrl) {
      toggle();
    } else {
      editOrganization(id, {
        name: organizationName,
        logo_url: organizationLogoUrl,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="Organization.settings.title" />
      </ModalHeader>
      <ModalBody>
        <Form autoComplete="off">
          <InputCustom
            name="name"
            label={<FormattedMessage id="Organization.settings.name" />}
            handleChange={handleNameChange}
            value={organizationName}
          />
          <InputCustom
            name="url"
            label={<FormattedMessage id="Organization.settings.logoUrl" />}
            handleChange={handleUrlChange}
            value={organizationLogoUrl}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            setOrganizationName(name);
            toggle();
          }}
        >
          <FormattedMessage id="Organization.settings.cancel" />
        </Button>
        <LoadingButton
          isLoading={isLoading}
          isSucceed={isSuccess}
          isFailed={!!error}
          callback={toggle}
          value="Organization.settings.save"
          onClick={handleUpdateOrganization}
          type="submit"
        />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({
  organization: {
    updating: { isLoading, isSuccess, error },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  editOrganization: editOrganizationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrganizationSettings));
