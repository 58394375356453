const initialState = {
  isConfirming: false,
  confirmFailed: false,
  confirmSuccess: false,
  confirmError: [],
};

const confirmEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_EMAIL_CONFIRMING':
      return {
        ...state,
        isConfirming: true,
        confirmFailed: false,
        confirmSuccess: false,
      };

    case 'CONFIRM_EMAIL_SUCCESS':
      return {
        ...state,
        confirmSuccess: true,
        isConfirming: false,
      };

    case 'CONFIRM_EMAIL_FAILED':
      return {
        ...state,
        confirmError: action.errorMessage,
        confirmFailed: true,
        isConfirming: false,
      };

    default:
      return state;
  }
};

export default confirmEmailReducer;
