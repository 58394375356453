const initialState = {
  signingOut: false,
  signedOut: true,
  signingOutError: false,
};

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNING_OUT':
      return {
        ...state,
        signingOut: true,
        signedOut: false,
      };

    case 'SIGNED_OUT':
      return {
        ...state,
        signedOut: true,
        signingOut: false,
      };

    case 'SIGNING_OUT_FAILED':
      return {
        ...state,
        signingOut: false,
        signedOut: true,
        signingOutError: true,
      };

    default:
      return state;
  }
};

export default logoutReducer;
