/*
 * LanguageProvider
 *
 * This is translation component. It sets locales for given language
 * and sets translation messages
 */

import React from 'react';
import flatten from 'flat';
import noLocaleData from 'react-intl/locale-data/no';
import { addLocaleData, IntlProvider } from 'react-intl';
import enTranslationMessages from './translations/en.json';
import noTranslationMessages from './translations/no.json';
import LocalLanguage from './helpers/localCulture';

/**
 * Sets locales for other languages than english
 */
addLocaleData(noLocaleData);

/**
 * Translations messages
 *
 */
const translations = {
  en: enTranslationMessages,
  no: noTranslationMessages,
};

export default class LanguageProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      language: null,
      messages: null,
    };
    this.LocalCulture = new LocalLanguage();
  }

  componentWillMount() {
    const language = this.LocalCulture.getLangWithoutRegion();
    this.changeLanguage(language);
  }

  /**
   * Change app language
   * @param {String} language
   */
  changeLanguage = (language) => {
    this.setState({
      messages: flatten(translations[language]),
      language,
    });
  };

  render() {
    const { language, messages } = this.state;
    const { children } = this.props;

    return (
      <IntlProvider locale={language} messages={messages}>
        {React.Children.only(children)}
      </IntlProvider>
    );
  }
}
