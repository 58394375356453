import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { AppInsights } from 'applicationinsights-js';
import { Provider } from 'react-redux';
import store, { history } from './store';
import LanguageProvider from './LanguageProvider';
import App from './App';

import './assets/styles/base.scss';

if (process.env.REACT_APP_APPINSIGHTS_KEY) {
  AppInsights.downloadAndSetup({
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
  });
}

render(
  <Provider store={store}>
    <LanguageProvider>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </LanguageProvider>
  </Provider>,
  document.getElementById('root')
);

// Unregister cached service workers from clients browsers
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
