import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// COMPONENTS:
import UnlinkedGraspsHeader from '../UnlinkedGraspsTable/UnlinkedGraspsHeader';
import GraspsPoolRow from './GraspsPoolRow';
import InfiniteTable from '../../../../../components/InfiniteTable';

// ACTIONS:
import {
  fetchUnassignedGraspsAction,
  clearUnassignedGraspsStoreAction,
  changeUnassignedGraspPageAction,
} from './reducer';

const GraspsPoolTable = ({
  unassignedGrasps,
  match,
  pagination,
  fetchUnassignedGrasps,
  changeUnassignedGraspPage,
  clearUnassignedGrasps,
}) => {
  const fetchGrasps = () => fetchUnassignedGrasps(match.params.id);

  useEffect(
    () => {
      fetchGrasps();
      return () => clearUnassignedGrasps();
    },
    [],
  );

  const fetchMoreGrasps = () => {
    if (pagination) {
      const nextPage = pagination.current_page + 1;
      changeUnassignedGraspPage(nextPage, match.params.id);
    }
  };

  const GraspsPoolRowComponent = props => <GraspsPoolRow {...props} />;
  const UnlinkedGraspsHeaderComponent = props => <UnlinkedGraspsHeader {...props} />;

  return (
    <>
      <InfiniteTable
        tableHeader={UnlinkedGraspsHeaderComponent}
        tableRow={GraspsPoolRowComponent}
        items={unassignedGrasps}
        pagination={pagination}
        fetchMore={fetchMoreGrasps}
        scrollableTarget="infinite-scroll-parent"
      />
    </>
  );
};

const mapStateToProps = ({
  organization: {
    unassignedGrasps: {
      list,
      pagination,
    },
  },
}) => ({
  pagination,
  unassignedGrasps: list,
});

const mapDispatchToProps = {
  fetchUnassignedGrasps: fetchUnassignedGraspsAction,
  changeUnassignedGraspPage: changeUnassignedGraspPageAction,
  clearUnassignedGrasps: clearUnassignedGraspsStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GraspsPoolTable));
