const initialState = {
  isNewPasswordSetting: false,
  settingFailed: false,
  settingSuccess: false,
  settingError: [],
};

const setPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_NEW_PASSWORD_SETTING':
      return {
          ...state,
          isNewPasswordSetting: true,
          settingFailed: false,
          settingSuccess: false,
        };

    case 'SET_NEW_PASSWORD_SUCCESS':
      return {
          ...state,
          settingSuccess: true,
          isNewPasswordSetting: false,
        };

    case 'SET_NEW_PASSWORD_FAILED':
      return {
          ...state,
          settingError: action.errorMessage,
          settingFailed: true,
          isNewPasswordSetting: false,
        };

    default:
      return state;
  }
};

export default setPasswordReducer;
