import React from 'react';
import EventLogDate from './EventLogDate';
import EventLogTime from './EventLogTime';
import EventLogEvent from './EventLogEvent';
// import EventLogAction from './EventLogAction';

const EventLogRow = ({ date, log, type }) => {
  return (
    <tr>
      <EventLogDate date={date} />
      <EventLogTime time={date} />
      <EventLogEvent eventLog={log} eventType={type} />
      {/* <EventLogAction action={action} /> */}
    </tr>
  );
};

export default EventLogRow;
