import React, { useState } from 'react';
import { Fade } from 'reactstrap';

/**
 * Returns proper device row component
 */
const ExtendableRow = ({
  rowComponent: Row,
  extenderComponent: Extender,
  ...rest
}) => {
  const [isRowExpanded, updateRowExpanded] = useState(false);
  const toggleRowExpanding = () => updateRowExpanded(!isRowExpanded);

  return (
    <>
      <Row
        {...rest}
        toggleExpand={toggleRowExpanding}
        isRowExpanded={isRowExpanded}
      />

      <Fade
        tag="tr"
        in={isRowExpanded}
        mountOnEnter
        unmountOnExit
        className="border-top-0"
      >
        <Extender {...rest} />
      </Fade>
    </>
  );
};

export default ExtendableRow;
