import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from 'reactstrap';

// COMPONENTS:
import InputCustom from '../../../../components/forms/InputCustom';
import LoadingButton from '../../../../components/LoadingButton';

/**
 * Modal with form for creating new endpoint
 */
const AddEndpointModal = ({
  isOpen,
  toggle,
  isLoading,
  isSuccess,
  error,
  match,
  addEndpoint,
}) => {
  const [inputValue, updateInputValue] = useState('');
  const addRef = createRef();

  useEffect(() => addRef.current.focus(), []);

  const handleChange = ({ target }) => updateInputValue(target.value);

  const handleCreate = (e) => {
    e.preventDefault();
    const model = {
      name: inputValue,
    };
    addEndpoint(match.params.id, model);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <FormattedMessage id="Endpoint.createEndpointHeader" />
      </ModalHeader>

      <Form autoComplete="off" onSubmit={handleCreate}>
        <ModalBody>
          <InputCustom
            id="add-endpoint-name"
            innerRef={addRef}
            name="name"
            label={<FormattedMessage id="AddOrganization.name" />}
            handleChange={handleChange}
            value={inputValue}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="light" onClick={toggle}>
            <FormattedMessage id="Endpoint.close" />
          </Button>

          <LoadingButton
            isLoading={isLoading}
            isSucceed={isSuccess}
            isFailed={!!error}
            callback={toggle}
            value="Endpoint.create"
            type="submit"
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  organization: {
    endpoint: {
      creating: { isLoading, isSuccess, error },
    },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
});

export default connect(mapStateToProps, null)(withRouter(AddEndpointModal));
