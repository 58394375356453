import React from 'react';
import { FormattedMessage } from 'react-intl';

const TableTitle = ({
  titleId,
  subTitleId,
}) => (
  <div className="mb-4">
    <h5 className="mb-0">
      <FormattedMessage id={titleId} />
    </h5>

    {
      subTitleId &&
      <small>
        <FormattedMessage id={subTitleId} />
      </small>
    }
  </div>
);

export default TableTitle;
