import { getEndpointGrasps } from '../../../../../api/organizations';
import withPaginationReducer, { initialState } from '../../../../../helpers/HOR/withPagination/reducer';
import {
  changePageAction,
  withPaginationActions,
  clearReducerStoreAction,
} from '../../../../../helpers/HOR/withPagination/actions';

// HELPERS:
const MODULE_NAME = 'LINKED_GRASPS';
const linkedGraspsInitialState = { ...initialState, filterBy: 'OnlyLinked', pageSize: 6 };
const linkedGraspsStore = getState => getState().organization.endpoint.linkedGrasps;

// REDUCER:
export const linkedGraspsReducer = withPaginationReducer(MODULE_NAME, linkedGraspsInitialState);

// ACTIONS:
export const fetchLinkedGraspsAction = withPaginationActions(
  MODULE_NAME,
  getEndpointGrasps,
  linkedGraspsStore,
);

export const changeLinkedGraspsPageAction = changePageAction(MODULE_NAME, fetchLinkedGraspsAction);
export const clearLinkedGraspsStoreAction = clearReducerStoreAction(MODULE_NAME);
