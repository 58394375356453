import React from 'react';
import { connect } from "react-redux";

// COMPONENTS:
import ConfirmEmailForm from "./components/ConfirmEmailForm";
import MembershipHeader from "../MembershipHeader";
import { confirmEmailAction } from "../confirm-email/actions";

const ConfirmEmail = ({ isLoading, isSuccess, confirmFailed, confirmEmail, match }) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    const { id, code } = match.params;
    confirmEmail(id, code);
  };

  return (
    <>
      <MembershipHeader id="ConfirmEmail.header" />
      <ConfirmEmailForm
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        confirmFailed={confirmFailed}
      />
    </>
  );
};

const mapStateToProps = ({ membership: { confirmEmail } }) => ({
  isLoading: confirmEmail.isConfirming,
  isSucceed: confirmEmail.confirmSuccess,
  confirmFailed: confirmEmail.confirmFailed,
});

const mapDispatchToProps = {
  confirmEmail: confirmEmailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
