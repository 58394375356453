import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog } from '@fortawesome/pro-solid-svg-icons';

// COMPONENTS:
import AssignGraspFromPoolModal from './AssignGraspFromPoolModal';
import EndpointSettings from './EndpointSettings';

const Header = ({ name }) => {
  const [assignModal, setAssignModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);

  const assignModalToggle = () => setAssignModal(!assignModal);
  const settingsModalToggle = () => setSettingsModal(!settingsModal);

  return (
    <Row className="mb-5">
      <Col xs={12} sm={6}>
        <small>
          <FormattedMessage id="OrganizationEndpoints.endpoint" />
        </small>
        <div className="d-flex align-items-center">
          <h2 className="mb-0">{name}</h2>
          <div
            style={{ cursor: 'pointer' }}
            className="ml-2 mt-1 text-primary"
            onClick={() => setSettingsModal(true)}
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} className="text-sm-right pt-3">
        <Button color="primary" onClick={() => setAssignModal(true)}>
          <FontAwesomeIcon icon={faPlus} className="mr-1" />
          <FormattedMessage id="OrganizationEndpoints.assignGrasp" />
        </Button>
      </Col>
      <AssignGraspFromPoolModal
        isOpen={assignModal}
        toggle={assignModalToggle}
      />
      <EndpointSettings
        name={name}
        isOpen={settingsModal}
        toggle={settingsModalToggle}
      />
    </Row>
  );
};

export default Header;
