import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ActionsDetailsModal = ({
  toggle,
  isOpen,
}) => (
  <Modal
    centered
    isOpen={isOpen}
    toggle={toggle}
  >
    <ModalHeader toggle={toggle}>
      <FormattedMessage id="OrganizationGrasps.detailsModal.header" />
    </ModalHeader>

    <ModalBody>
      <p className="mb-2">
        <span className="text-uppercase font-weight-bold">
          <FormattedMessage id="OrganizationGrasps.detailsModal.forceUnlinkTitle" />
        </span>
        <span>
          <FormattedMessage id="OrganizationGrasps.detailsModal.forceUnlinkText" />
        </span>
        <br />
        <span className="text-uppercase font-weight-bold">
          <FormattedMessage id="OrganizationGrasps.detailsModal.useCase" />
        </span>
        <span>
          <FormattedMessage id="OrganizationGrasps.detailsModal.forceUnlinkCase" />
        </span>
      </p>

      <hr />

      <p className="mb-2">
        <span className="text-uppercase font-weight-bold">
          <FormattedMessage id="OrganizationGrasps.detailsModal.unassignTitle" />
        </span>
        <span>
          <FormattedMessage id="OrganizationGrasps.detailsModal.unassignText" />
        </span>
        <br />
        <span className="text-uppercase font-weight-bold">
          <FormattedMessage id="OrganizationGrasps.detailsModal.useCase" />
        </span>
        <span>
          <FormattedMessage id="OrganizationGrasps.detailsModal.unassignCase" />
        </span>
      </p>

      <hr />

      <p className="mb-2">
        <span className="text-uppercase font-weight-bold">
          <FormattedMessage id="OrganizationGrasps.detailsModal.disownTitle" />
        </span>
        <span>
          <FormattedMessage id="OrganizationGrasps.detailsModal.disownText" />
        </span>
        <br />
        <span className="text-uppercase font-weight-bold">
          <FormattedMessage id="OrganizationGrasps.detailsModal.useCase" />
        </span>
        <span>
          <FormattedMessage id="OrganizationGrasps.detailsModal.disownCase" />
        </span>
      </p>
    </ModalBody>
  </Modal>
);

export default ActionsDetailsModal;
