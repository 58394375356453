const initialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
  data: null,
  meta: null,
};

/**
 * Higher Order Reducer for getting async data
 * @param name - dynamic actions name
 */
const fetchAsyncWithDataReducers = name => (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case `FETCH_${name}_STARTED`:
      return {
        isLoading: true,
        isSuccess: false,
        error: null,
        data: null,
        meta: null,
      };

    case `FETCH_${name}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.data,
        meta: action.meta,
      };

    case `FETCH_${name}_ERROR`:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case `CLEAR_${name}_STORE`:
      return initialState;

    default:
      return state;
  }
};

export default fetchAsyncWithDataReducers;
