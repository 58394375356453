import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-solid-svg-icons';

const EventLogBackButton = () => (
  <Row className="mb-4">
    <Col>
      <Link to="/grasp-inventory">
        <FontAwesomeIcon icon={faLongArrowLeft} className="mr-2" />
        <FormattedMessage id="EventLog.buttonText" />
      </Link>
    </Col>
  </Row>
);

export default EventLogBackButton;
