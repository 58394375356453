import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Form, Modal, ModalBody, ModalHeader, ModalFooter, Button,
} from 'reactstrap';

// ACTIONS:
import { addOrganizationAction } from '../reducer';

// COMPONENTS:
import InputCustom from '../../../components/forms/InputCustom';
import LoadingButton from '../../../components/LoadingButton';
import AlertErrors from '../../../components/AlertErrors';

// CONSTANTS:
const organizationTypes = [
  'healthcare_provider',
  'educational_institute',
  'non_healthcare_business_or_corporation',
];

const defaultFieldsValues = {
  name: '',
  logoUrl: '',
  organizationType: organizationTypes[0],
};

/**
 * Returns modal for creating organization
 */
const AddOrganizationModal = ({
  isLoading,
  isSuccess,
  error,
  isOpen,
  toggle,
  intl: { formatMessage },
  addOrganization,
}) => {
  const [fieldsValues, updateFieldValues] = useState({ ...defaultFieldsValues });
  const nameInputRef = React.createRef();

  // Clears fields values on unmount
  useEffect(() => {
    updateFieldValues({ ...defaultFieldsValues });
    nameInputRef.current.focus();
  }, []);

  const updateField = ({ target: { name, value } }) => {
    updateFieldValues({ ...fieldsValues, [name]: value });
  };

  const handleCreate = (e) => {
    e.preventDefault();
    const { name, logoUrl, organizationType } = fieldsValues;
    const model = {
      name,
      logo_url: logoUrl,
      type_value: organizationType,
    };
    addOrganization(model);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="text-muted text-center">
        <FormattedMessage id="AddOrganization.createNewOrganization" />
      </ModalHeader>

      <ModalBody className="px-4 pb-4">
        {!!error && <AlertErrors errors={error.data.meta.errors} visible={error.data.meta.has_errors} />}

        <Form autoComplete="off">
          <InputCustom
            id="add-organization-name"
            name="name"
            required
            label={<FormattedMessage id="AddOrganization.name" />}
            handleChange={updateField}
            value={fieldsValues.name}
            innerRef={nameInputRef}
          />

          <InputCustom
            id="add-organization-logoUrl"
            name="logoUrl"
            label={<FormattedMessage id="AddOrganization.logoUrl" />}
            handleChange={updateField}
            value={fieldsValues.logoUrl}
          />

          <InputCustom
            id="add-organization-organizationType"
            name="organizationType"
            label={<FormattedMessage id="AddOrganization.chooseOrganization" />}
            handleChange={updateField}
            value={fieldsValues.organizationType}
            type="select"
            options={organizationTypes.map(option => (
              <option key={option} value={option}>
                {formatMessage({ id: `AddOrganization.organizationsOptions.${option}` })}
              </option>
            ))}
          />
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="light" onClick={toggle}>
          <FormattedMessage id="AddOrganization.close" />
        </Button>

        <LoadingButton
          onClick={handleCreate}
          isLoading={isLoading}
          isSucceed={isSuccess}
          isFailed={error}
          callback={toggle}
          value="AddOrganization.createOrganization"
          type="submit"
        />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({
  organization: {
    creating: {
      isLoading,
      isSuccess,
      error,
    },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  addOrganization: addOrganizationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddOrganizationModal));
