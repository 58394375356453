import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner';

/**
 * Renders list of devices
 * @param list
 * @param isLoading
 * @param isFailed
 * @param tableRow - component that will render table row
 * @param FailComponent - component that will render while error situation
 * @param EmptyListComponent - component that will render if list is empty
 */
const TableComponent = ({
  className,
  list,
  isLoading,
  isFailed,
  tableHeader: TableHeader = Fragment,
  headerProps = {},
  tableRow: TableRow,
  rowProps = {},
  failComponent: FailComponent = () => <FormattedMessage id="EndpointDetails.noDevices" />,
  emptyList: EmptyListComponent = () => <FormattedMessage id="GraspInventory.emptyList" />,
}) => {
  if (isFailed) return <FailComponent />;

  return (
    <Table className={className}>
      <TableHeader {...headerProps} />
      <tbody>
        {(() => {
          switch (true) {
            case isLoading:
              return (
                <tr>
                  <td colSpan={20}>
                    <LoadingSpinner
                      size="2x"
                      className="py-5 text-center"
                    />
                  </td>
                </tr>
              );

            case !list || (list && !list.length):
              return (
                <tr>
                  <td colSpan={20}>
                    <h5 className="text-center py-5">
                      <EmptyListComponent />
                    </h5>
                  </td>
                </tr>
              );

            case list && !!list.length:
              return list.map(itemProps => (
                <TableRow
                  key={JSON.stringify(itemProps)}
                  {...itemProps}
                  {...rowProps}
                />
              ));

            default:
              return null;
          }
        })()}
      </tbody>
    </Table>
  );
};

export default TableComponent;
