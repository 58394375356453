import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const SortableColumn = ({
  columnName,
  sortBy,
  changeSortBy,
  translationId,
  className,
  colWidth,
  disabled,
  ...rest
}) => {
  const initialState = { isAsc: false, isDesc: false };
  const [{ isAsc, isDesc }, setSortValue] = useState(initialState);

  const sortStates = {
    ASC: `${columnName}Asc`,
    DESC: `${columnName}Desc`,
  };

  const checkIfSortByMatchSortStates = () => {
    if (sortBy === sortStates.ASC) setSortValue({ isAsc: true, isDesc: false });
    else if (sortBy === sortStates.DESC) setSortValue({ isAsc: false, isDesc: true });
    else setSortValue({ isAsc: false, isDesc: false });
  };

  useEffect(
    () => { checkIfSortByMatchSortStates(); },
    [sortBy],
  );

  // eslint-disable-next-line
  const handleClick = () => {
    if (disabled) return null;

    if (!isAsc && !isDesc) changeSortBy(sortStates.ASC);
    else if (isAsc && !isDesc) changeSortBy(sortStates.DESC);
    else changeSortBy(null);
  };

  return (
    <th style={{ minWidth: colWidth }} {...rest} onClick={handleClick}>
      <div className={`${className || ''} text-nowrap d-flex align-items-center justify-content-between`}>
        <FormattedMessage id={translationId} />

        {isAsc && <FontAwesomeIcon icon={faCaretUp} />}
        {isDesc && <FontAwesomeIcon icon={faCaretDown} />}
      </div>
    </th>
  );
};

export default SortableColumn;
