import React, { useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// COMPONENTS:
import SetPasswordForm from "./components/SetPasswordForm";
import MembershipHeader from "../MembershipHeader";
import { setPasswordAction } from "../set-password/actions";

const SetPassword = ({ isLoading, isSuccess, settingFailed, setPassword, match }) => {
  const [values, setValues] = useState({ password: '', passwordRepeat: '' });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { token } = match.params;
    setPassword(values, token);
  };

  return (
    <>
      <MembershipHeader id="SetPassword.header" />
      <SetPasswordForm
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        settingFailed={settingFailed}
      />
    </>
  );
};

const mapStateToProps = ({ membership: { setPassword } }) => ({
  isLoading: setPassword.isNewPasswordSetting,
  isSucceed: setPassword.settingSuccess,
  settingFailed: setPassword.settingFailed,
});

const mapDispatchToProps = {
  setPassword: setPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetPassword));
