import React, { Fragment, memo } from 'react';

const GraspName = ({ uuid, name }) => (
  <Fragment>
    <p className="mb-0 font-weight-bold">{name}</p>
    <small className="text-nowrap">{uuid}</small>
  </Fragment>
);

export default memo(GraspName);
