import React from 'react';
import { FormattedMessage } from 'react-intl';

const LinkedGraspTableHeader = () => (
  <thead>
    <tr>
      <th style={{ width: '40%' }}>
        <FormattedMessage id="OrganizationGrasps.headers.name" />
      </th>
      <th style={{ width: '22%' }}>
        <FormattedMessage id="OrganizationGrasps.headers.added" />
      </th>
      <th style={{ width: '18%' }}>
        <FormattedMessage id="OrganizationGrasps.headers.battery" />
      </th>
      <th style={{ width: '20%' }}>
        <FormattedMessage id="OrganizationGrasps.headers.details" />
      </th>
    </tr>
  </thead>
);

export default LinkedGraspTableHeader;
