import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

// COMPONENTS:
import EndpointsList from './components/EndpointsList';
import AddEndpointModal from '../dashboard/components/AddEndpointModal';
import LoadingSpinner from '../../../components/LoadingSpinner';

// ACTIONS:
import { fetchEndpointsAction, addEndpointAction, changeEndpointsPageAction } from './reducer';
import Pagination from '../../../components/Pagination';

const OrganizationEndpoints = ({
  list,
  isLoading,
  isSuccess,
  pagination,
  error,
  fetchEndpoints,
  addEndpoint,
  changeEndpointsPage,
  match,
}) => {
  const [isAddEndpointModalOpen, updateAddEndpointModalState] = useState(false);

  const toggleAddEndpointModal = () => {
    updateAddEndpointModalState(!isAddEndpointModalOpen);
  };

  useEffect(
    () => {
      fetchEndpoints(match.params.id);
    }, [],
  );

  const changePage = page => changeEndpointsPage(page, match.params.id);

  return (
    <Container className="pb-5 mb-5">
      <Row>
        <Col xs={12} md={6}>
          <h2 className="mb-4">
            <FormattedMessage id="OrganizationEndpoints.listTitle" />
          </h2>
        </Col>

        <Col xs={12} md={6} className="text-left text-md-right">
          <Button
            color="primary"
            onClick={toggleAddEndpointModal}
          >
            <FormattedMessage id="OrganizationEndpoints.add" />
          </Button>
        </Col>
      </Row>


      {(() => {
        const isListEmpty = isSuccess && !list.length;

        switch (true) {
          case isLoading:
            return <LoadingSpinner className="text-center w-100" />;

          case isListEmpty:
            return <div className="px-1"><FormattedMessage id="OrganizationEndpoints.emptyList" /></div>;

          case isSuccess:
            return (
              <>
                <Row>
                  <EndpointsList
                    list={list}
                  />
                </Row>
                <Row>
                  <Pagination
                    pagination={pagination}
                    getData={changePage}
                  />
                </Row>
              </>
            );

          case error:
            return <p> failed </p>;

          default:
            return null;
        }
      })()}
      {
        isAddEndpointModalOpen &&
          <AddEndpointModal
            isOpen={isAddEndpointModalOpen}
            toggle={toggleAddEndpointModal}
            addEndpoint={addEndpoint}
          />
      }
    </Container>
  );
};

const mapStateToProps = ({
  organization: {
    endpoints: {
      list,
      pagination,
      isLoading,
      isSuccess,
      error,
    },
  },
}) => ({
  list,
  pagination,
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  fetchEndpoints: fetchEndpointsAction,
  addEndpoint: addEndpointAction,
  changeEndpointsPage: changeEndpointsPageAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrganizationEndpoints));
