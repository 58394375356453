import Axios from 'axios';
import request from '../request';

export const register = (data) =>
  request({
    url: '/users/register',
    method: 'POST',
    data,
  });

export const signIn = (data) =>
  request({
    url: 'users/login',
    method: 'POST',
    data,
  });

export const signOut = (data) =>
  request({
    url: '/users/log_out',
    method: 'POST',
    data,
  });

export const resetPassword = (data) =>
  request({
    url: '/users/forgot_password',
    method: 'POST',
    data,
  });

export const setPassword = (data) =>
  request({
    url: '/users/set_password',
    method: 'POST',
    data,
  });

export const resendEmail = (data) =>
  request({
    url: '/users/resend_confirmation_email',
    method: 'POST',
    data,
  });

export const confirmEmail = (data) =>
  request({
    url: '/users/confirm_email',
    method: 'POST',
    data,
  });

export const getUrlFromDiscoveryService = () =>
  Axios.get(process.env.REACT_APP_DISCOVERY_SERVICE || '');

export const refreshAccessToken = () =>
  request({
    url: `/users/new_access_token`,
    method: 'GET',
  });
