import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// ACTIONS:
import { fetchOrganizations, changeOrganizationsPageAction, clearOrganizationsStoreAction } from '../../../reducer';

// COMPONENTS:
import InfiniteTable from '../../../../../components/InfiniteTable';
import CardRow from './CardRow';

const AssignToOrganizationModal = ({
  isOpen,
  toggle,
  className,
  fetchOrganizationsList,
  changeOrganizationsPage,
  clearOrganizationsStore,
  list,
  pagination,
  action,
}) => {
  useEffect(
    () => {
      fetchOrganizationsList();
      return () => clearOrganizationsStore();
    },
    [],
  );

  const OrganizationRowComponent = props => <CardRow action={action} {...props} />;
  const fetchMoreOrganizations = () => {
    const nextPage = pagination.current_page + 1;
    changeOrganizationsPage(nextPage);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} scrollable>
      <ModalHeader toggle={toggle}><FormattedMessage id="GraspInventory.modals.organizationsTitle" /></ModalHeader>
      <ModalBody style={{ maxHeight: '900px', marginBottom: '1px' }} id="infinite-scroll-organization-parent">
        <InfiniteTable
          items={list}
          pagination={pagination}
          tableRow={OrganizationRowComponent}
          fetchMore={fetchMoreOrganizations}
          scrollableTarget="infinite-scroll-organization-parent"
          isTable={false}
        />
      </ModalBody>
    </Modal>
  );
};


const mapStateToProps = ({
  inventory: {
    organizations: {
      list,
      pagination,
      isLoading,
      error,
    },
  },
}) => ({
  list,
  pagination,
  isLoading,
  error,
});

const mapDispatchToProps = {
  fetchOrganizationsList: fetchOrganizations,
  changeOrganizationsPage: changeOrganizationsPageAction,
  clearOrganizationsStore: clearOrganizationsStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignToOrganizationModal);
