import React from 'react';
import { FormattedMessage } from 'react-intl';

const UnlinkedGraspTableHeader = () => (
  <thead>
    <tr>
      <th style={{ width: '65%' }}>
        <FormattedMessage id="OrganizationGrasps.headers.name" />
      </th>
      <th className="text-right pr-4" style={{ width: '35%' }}>
        <FormattedMessage id="OrganizationGrasps.headers.actions" />
      </th>
    </tr>
  </thead>
);

export default UnlinkedGraspTableHeader;
