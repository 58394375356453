import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

const UserBlock = ({ user }) => {
  const { first_name, last_name, email } = user || {};

  return (
    <Fragment>
      {first_name || last_name ? (
        <Fragment>
          <p className="mb-0 font-weight-bold text-nowrap">
            {`${first_name || ''} ${last_name || ''}`}
          </p>
          <small>{email || ''}</small>
        </Fragment>
      ) : (
        <FormattedMessage id="GraspInventory.table.notAssigned" />
      )}
    </Fragment>
  );
};

export default UserBlock;
