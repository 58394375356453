import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import Table from '../../../../../components/Table';
import Pagination from '../../../../../components/Pagination';
import LinkedGraspsHeader from './LinkedGraspsHeader';
import LinkedGraspsRow from './LinkedGraspsRow';
import ExtendedLinkedGraspRow from './ExtendedLinkedGraspRow';
import ExtendableRow from '../../../../../components/Table/ExtendableRow';

// ACTIONS:
import {
  fetchLinkedGraspsAction,
  changeLinkedGraspsPageAction,
  clearLinkedGraspsStoreAction,
} from './reducer';

const LinkedGraspsTable = ({
  isLoading,
  error,
  linkedGrasps,
  pagination,
  match,
  fetchLinkedGrasps,
  changeLinkedGraspsPage,
  clearLinkedGraspsStore,
}) => {
  const { id, endpointId } = match.params;
  const getLinkedGrasps = () => {
    fetchLinkedGrasps(id, endpointId);
  };

  const changePage = (page) => changeLinkedGraspsPage(page, id, endpointId);

  useEffect(() => {
    getLinkedGrasps();
    return () => clearLinkedGraspsStore();
  }, []);

  const ExtendableLinkedGraspsRow = (props) => (
    <ExtendableRow
      {...props}
      rowComponent={LinkedGraspsRow}
      extenderComponent={ExtendedLinkedGraspRow}
    />
  );
  const EmptyListComponent = () => (
    <FormattedMessage id="EndpointDetails.linkedList.emptyList" />
  );

  return (
    <>
      <Table
        list={linkedGrasps}
        isLoading={isLoading}
        isFailed={error}
        tableRow={ExtendableLinkedGraspsRow}
        tableHeader={LinkedGraspsHeader}
        emptyList={EmptyListComponent}
      />
      <Pagination pagination={pagination} getData={changePage} />
    </>
  );
};

const mapStateToProps = ({
  organization: {
    endpoint: {
      linkedGrasps: { list, pagination, isLoading, isSuccess, error },
    },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
  linkedGrasps: list,
  pagination,
});

const mapDispatchToProps = {
  fetchLinkedGrasps: fetchLinkedGraspsAction,
  changeLinkedGraspsPage: changeLinkedGraspsPageAction,
  clearLinkedGraspsStore: clearLinkedGraspsStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LinkedGraspsTable));
