import { push } from "react-router-redux";
import { resetPassword } from '../../../api/membership';

export const isPasswordResetting = () => ({
  type: 'IS_PASSWORD_RESETTING',
});

export const resetPasswordSuccess = () => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

export const resetFailed = errorMessage => ({
  type: 'RESET_PASSWORD_FAILED',
  errorMessage,
});

export const resetPasswordAction = (userEmail) => (dispatch) => {
  dispatch(isPasswordResetting());
  return resetPassword({ user_email: userEmail })
    .then(() => {
      dispatch(resetPasswordSuccess());
      dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'password_reset_success' } });
      dispatch(push('/login'));
    })
    .catch((error) => {
      if (
        error
        && error.data
        && error.data.meta.errors[0]
      ) {
        if (error.data.meta.errors[0].code === 'user_email_not_confirmed_error') {
          sessionStorage.setItem('email', userEmail);
          dispatch(push('/resend-email'));
        }
        dispatch(resetFailed(''));
        error.data.meta.errors.map(
          singleError => dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: singleError.code } }),
        );
      } else {
        dispatch(resetFailed(''));
        dispatch({ type: 'DISPLAY_NOTIFICATION', notification: { code: 'reset_password_failed' } });
      }
    });
};
