import {
  getOrganizationGrasps,
  unassignGraspFromEndpoint,
  removeGraspFromOrganization,
  unlinkGraspFromUser,
} from '../../../api/organizations';

// REDUCERS:
import withPaginationReducer from '../../../helpers/HOR/withPagination/reducer';

// ACTIONS:
import {
  withPaginationActions,
  changePageAction,
  changeFilterByAction,
  clearReducerStoreAction,
} from '../../../helpers/HOR/withPagination/actions';
import { asyncActions } from '../../../helpers/HOR/asyncAction/actions';

// HELPERS:
const GRASPS_FROM_ORGANIZATION = 'GRASPS_FROM_ORGANIZATION';
const organizationGraspsStore = getState => getState().organization.grasps;

const UNASSIGN_GRASP_FROM_ENDPOINT = 'UNASSIGN_GRASP_FROM_ENDPOINT';
const DISOWN_GRASP = 'DISOWN_GRASP';
const FORCE_UNLINK_GRASP = 'FORCE_UNLINK_GRASP';

// REDUCER:
export const organizationGraspsReducer = withPaginationReducer(GRASPS_FROM_ORGANIZATION);

// ACTIONS:
export const fetchGraspsFromOrganizationAction = withPaginationActions(
  GRASPS_FROM_ORGANIZATION,
  getOrganizationGrasps,
  organizationGraspsStore,
);

const unassignGraspAsyncActionParameters = {
  name: UNASSIGN_GRASP_FROM_ENDPOINT,
  APICall: unassignGraspFromEndpoint,
  callback: (data, organizationId) => fetchGraspsFromOrganizationAction(organizationId),
  onSuccessNotification: { code: 'grasp_unassigned' },
};

export const unassignGraspFromEndpointAction = asyncActions(unassignGraspAsyncActionParameters);

const disownGraspAsyncActionParameters = {
  name: DISOWN_GRASP,
  APICall: removeGraspFromOrganization,
  callback: (data, organizationId) => fetchGraspsFromOrganizationAction(organizationId),
  onSuccessNotification: { code: 'grasp_disowned' },
};

export const removeGraspFromOrganizationAction = asyncActions(disownGraspAsyncActionParameters);

const forceUnlinkGraspAsyncActionParameters = {
  name: FORCE_UNLINK_GRASP,
  APICall: unlinkGraspFromUser,
  callback: (data, organizationId) => fetchGraspsFromOrganizationAction(organizationId),
  onSuccessNotification: { code: 'grasp_force_unlink' },
};

export const unlinkGraspFromUserAction = asyncActions(forceUnlinkGraspAsyncActionParameters);

export const changeOrganizationGraspsPageAction = changePageAction(
  GRASPS_FROM_ORGANIZATION,
  fetchGraspsFromOrganizationAction,
);

export const changeOrganizationGraspsFilterAction = changeFilterByAction(
  GRASPS_FROM_ORGANIZATION,
  fetchGraspsFromOrganizationAction,
);

export const clearOrganizationGraspsStoreAction = clearReducerStoreAction(
  GRASPS_FROM_ORGANIZATION,
);
