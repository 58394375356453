import { deleteTokens, setTokens } from '../helpers/authentication';
import { push } from 'react-router-redux';
import Cookies from 'js-cookie';
import store from '../store';
import { displayNotificationAction } from '../modules/notifications/actions';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { refreshAccessToken } from './membership';
import { Button } from 'reactstrap';

let timeout;

const setTokenData = (accessToken, expirationDate) => {
  setTokens(accessToken, expirationDate);
};

const onRefreshClick = async () => {
  const response = await refreshAccessToken();
  try {
    const { token, valid_until } = response.data.data || {};
    setTokenData(token, valid_until);
    timeout = returnSetTimeoutWithToastNotification(valid_until);
  } catch (err) {
    clearTimeout(timeout);
  }
};

const displaySingOutToast = () => {
  store.dispatch(
    displayNotificationAction({
      jsx: (
        <div
          style={{
            width: '150px',
            fontSize: '12px',
          }}
        >
          <FormattedMessage id="Notification.signOutNotification" />
          <Button
            onClick={onRefreshClick}
            color="primary"
            className="mt-2"
            style={{
              fontSize: '12px',
            }}
          >
            <FormattedMessage id="Notification.refresh_token" />
          </Button>
        </div>
      ),
    })
  );
};

const returnSetTimeoutWithToastNotification = (expDateString) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  const secondsBeforeNotification = process.env
    .REACT_APP_REFRESH_TOKEN_TOAST_SECONDS_BEFORE_EXPIRATION
    ? parseInt(
        process.env.REACT_APP_REFRESH_TOKEN_TOAST_SECONDS_BEFORE_EXPIRATION
      )
    : 30;

  const MsBeforeNotification = secondsBeforeNotification * 1000;

  const differenceInMs =
    new Date(expDateString) - new Date() - MsBeforeNotification;

  if (expDateString && differenceInMs > 1) {
    return setTimeout(() => displaySingOutToast(), differenceInMs);
  } else {
    return null;
  }
};

export const refreshTokenInterceptor = (axiosClient, customOptions = {}) => {
  const errorInterceptor = (error) => {
    if (
      error.response.status === 401 ||
      localStorage.getItem('api_url') === null
    ) {
      deleteTokens();
      localStorage.removeItem('api_url');
      store.dispatch(push('/login'));
      clearTimeout(timeout);

      if (
        error &&
        error.response.config.url.includes('/invitation_requests/')
      ) {
        localStorage.setItem('redirectUrl', error.response.config.url);
      }
    }

    return Promise.reject(error);
  };

  const onResponseIntercept = (response) => {
    if (response.config.url.includes('login')) {
      const { valid_until } = response.data.data || {};
      timeout = returnSetTimeoutWithToastNotification(valid_until);
    } else if (
      response.headers['x-new-access-token'] &&
      response.headers['x-new-access-token-valid-until']
    ) {
      setTokenData(
        response.headers['x-new-access-token'],
        response.headers['x-new-access-token-valid-until']
      );

      timeout = returnSetTimeoutWithToastNotification(
        response.headers['x-new-access-token-valid-until']
      );
    } else {
      const cookieExpirationDateString = Cookies.get('token_expiration_date');
      if (
        !timeout &&
        cookieExpirationDateString &&
        !!Cookies.get('access_token')
      ) {
        timeout = returnSetTimeoutWithToastNotification(
          cookieExpirationDateString
        );
      }
    }

    return response;
  };

  axiosClient.interceptors.response.use(onResponseIntercept, errorInterceptor);
};
