import React from 'react';
import { FormattedMessage } from "react-intl";

const MembershipHeader = ({ id }) => (
  <h2 className="text-white text-center mb-3">
    <FormattedMessage id={id} />
  </h2>
);

export default MembershipHeader;
