import React from 'react';
import { Form, FormGroup } from "reactstrap";
import InputCustom from "../../../../components/InputCustom";
import LoadingButton from "../../../../components/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

const ResendEmailForm = ({ intl, values, isLoading, isSuccess, resetFailed, handleChange, handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <InputCustom
          required
          id="resend-email"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          placeHolder={intl.formatMessage({ id: 'ResetPassword.email' })}
        />
      </FormGroup>

      <div className="text-center">
        <LoadingButton
          isLoading={isLoading}
          isSucceed={isSuccess}
          isFailed={resetFailed}
          value="ResendEmail.buttonText"
          type="submit"
          className="btn btn-outline-light btn-block btn-login"
        >
          <FontAwesomeIcon icon={faEnvelope} className="ml-1" />
        </LoadingButton>
      </div>
      <div className="mt-2 text-center">
        <Link to="login" className="text-white">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
          <FormattedMessage id="ResetPassword.back" />
        </Link>
      </div>
    </Form>
  );
};

export default injectIntl(ResendEmailForm);
