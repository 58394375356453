import React from 'react';
import { FormattedMessage } from 'react-intl';

const ExtendedRow = ({
  firmware_version,
  battery_level,
  uptime,
}) => (
  <td colSpan={6} className="pt-3 pb-4 px-4 border-top-0">
    <p className="mb-1"><FormattedMessage id="EndpointDetails.table.show.firmware" />: {firmware_version || 'N/A'}</p>
    <p className="mb-1"><FormattedMessage id="EndpointDetails.table.show.battery_level" />: {battery_level} </p>
    <p className="mb-1"><FormattedMessage id="EndpointDetails.table.show.uptime" />: {uptime}</p>
  </td>
);

export default ExtendedRow;
