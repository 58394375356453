import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

// ACTIONS:
import { fetchEndpoints, changeEndpointsPageAction, clearEndpointsStoreAction } from '../../../reducer';

// COMPONENTS:
import InfiniteTable from '../../../../../components/InfiniteTable';
import CardRow from './CardRow';

const AssignToOrganizationModal = ({
  isOpen,
  toggle,
  className,
  organizationId,
  fetchEndpointsList,
  changeEndpointsPage,
  clearStore,
  list,
  pagination,
  action,
}) => {
  useEffect(
    () => {
      fetchEndpointsList(organizationId);
      return clearStore;
    },
    [],
  );

  const EndpointRowComponent = props => <CardRow action={action} {...props} />;
  const fetchMoreEndpoints = () => {
    const nextPage = pagination.current_page + 1;
    changeEndpointsPage(nextPage, organizationId);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} scrollable>
      <ModalHeader toggle={toggle}><FormattedMessage id="GraspInventory.modals.endpointsTitle" /></ModalHeader>
      <ModalBody style={{ maxHeight: '900px', marginBottom: '1px' }} id="infinite-scroll-endpoints-parent">
        <InfiniteTable
          items={list}
          pagination={pagination}
          tableRow={EndpointRowComponent}
          fetchMore={fetchMoreEndpoints}
          scrollableTarget="infinite-scroll-endpoints-parent"
          isTable={false}
        />
      </ModalBody>
    </Modal>
  );
};


const mapStateToProps = ({
  inventory: {
    endpoints: {
      list,
      pagination,
      isLoading,
      error,
    },
  },
}) => ({
  list,
  pagination,
  isLoading,
  error,
});

const mapDispatchToProps = {
  fetchEndpointsList: fetchEndpoints,
  changeEndpointsPage: changeEndpointsPageAction,
  clearStore: clearEndpointsStoreAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignToOrganizationModal);
