import React, { useState } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';

// COMPONENTS:
import graspBlob from '../tableComponents/GraspBlob';
import request from '../../../../api/request';
import { displayNotificationAction } from '../../../notifications/actions';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const GraspInfo = ({
  hexColor,
  name,
  uuid,
  toggleExpand,
  graspId,
  organizationId,
  displayNotification,
  disabled,
}) => {
  const [colorValue, setColorValue] = useState(hexColor || '#ffffff');
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const handleColorChange = ({ currentTarget: { value } }) => {
    if (colorValue !== value) {
      setIsLoading(true);
      changeGraspHexColor(value);
    }
  };

  const changeGraspHexColor = (value) =>
    request({
      url: `/organizations/${organizationId}/grasps/${graspId}`,
      method: 'PATCH',
      data: {
        hex_color: value,
      },
    }).then(({ data }) => {
      setIsLoading(false);
      setColorValue(
        data.data.hex_color === '' ? '#ffffff' : data.data.hex_color
      );
      displayNotification({ code: 'hex_color_updated' });
    });

  return (
    <td>
      <div className="d-flex">
        <div className="d-inline-flex">
          {isLoading ? (
            <div
              style={{ width: 40 }}
              className="d-flex align-items-center justify-content-center"
            >
              <LoadingSpinner />
            </div>
          ) : (
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle
                type="button"
                color="text"
                disabled={!organizationId || disabled}
                className="grasp-blob-button"
                onClick={toggleDropdown}
              >
                {graspBlob(colorValue)}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem value="#2875b2" onClick={handleColorChange}>
                  <FormattedMessage id="AddGraspModal.colors.#2875b2" />
                </DropdownItem>
                <DropdownItem value="#f5842a" onClick={handleColorChange}>
                  <FormattedMessage id="AddGraspModal.colors.#f5842a" />
                </DropdownItem>
                <DropdownItem value="#48a447" onClick={handleColorChange}>
                  <FormattedMessage id="AddGraspModal.colors.#48a447" />
                </DropdownItem>
                <DropdownItem onClick={handleColorChange}>
                  <FormattedMessage id="AddGraspModal.colors.null" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>

        <div className="d-inline-block ml-1">
          <Button
            disabled={disabled}
            onClick={toggleExpand}
            color="link"
            className="d-block m-0 p-0 font-weight-bold text-nowrap"
          >
            {name}
          </Button>
          <small className="text-nowrap">{uuid}</small>
        </div>
      </div>
    </td>
  );
};

const mapDispatchToProps = {
  displayNotification: displayNotificationAction,
};

export default connect(null, mapDispatchToProps)(GraspInfo);
