import React, { useState } from 'react';

const OrganizationImage = (imgProps) => {
  const [imgState, setImageState] = useState(true);

  if (imgState) {
    return (
      <img
        alt="organization logo"
        className="mr-3"
        onError={() => setImageState(false)}
        {...imgProps}
      />
    );
  }

  return null;
};

export default OrganizationImage;
