const initialState = {
  isLoggingIn: false,
  loginFailed: false,
  loginSuccess: false,
  loginError: [],
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_LOGGING_IN':
      return {
          ...state,
          isLoggingIn: true,
          loginFailed: false,
          loginSuccess: false,
        };

    case 'LOGIN_SUCCESS':
      return {
          ...state,
          loginSuccess: true,
          isLoggingIn: false,
        };

    case 'LOGIN_FAILED':
      return {
          ...state,
          loginError: action.errorMessage,
          loginFailed: true,
          isLoggingIn: false,
        };

    default:
      return state;
  }
};

export default loginReducer;
