import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// COMPONENTS:
import GraspInfo from './tableColumns/GraspInfo';
import CreatedAt from './tableColumns/CreatedAt';
import UpdatedAt from './tableColumns/UpdatedAt';
import Organization from './tableColumns/Organization';
import Endpoint from './tableColumns/Endpoint';
import CreatedBy from './tableColumns/CreatedBy';
import OwnedBy from './tableColumns/OwnedBy';
import Status from './tableColumns/Status';
import Flashdate from './tableColumns/Flashdate';
import Numbertag from './tableColumns/Numbertag';
import SerialNumber from './tableColumns/SerialNumber';
import EventLog from './tableColumns/EventLog';
import ActionButton from './tableColumns/ActionButton';

const InventoryRow = ({
  id,
  physical_grasp_uuid,
  name,
  created_at,
  updated_at,
  endpoint,
  hex_color,
  created_by,
  owner,
  redirectTo,
  toggleExpand,
  status,
  flashdate,
  numbertag,
  is_assigned_to_endpoint,
  is_assigned_to_organization,
  is_linked_to_user,
  serial_number,
  meta,
  organization_id,
  organization,
}) => {
  const linkToOrganization = `/organization/${organization_id}`;
  const redirectToOrganization = (e) => {
    e.preventDefault();
    redirectTo(linkToOrganization);
  };

  const endpointId = endpoint ? endpoint.id : '';
  const linkToEndpoint = `/organization/${organization_id}/endpoints/${endpointId}`;
  const redirectToEndpoint = (e) => {
    e.preventDefault();
    redirectTo(linkToEndpoint);
  };

  const linkToEventLog = `/organization/${organization_id}/event-logs/${id}`;
  const redirectToEventLog = (e) => {
    e.preventDefault();
    redirectTo(linkToEventLog, {
      name,
      hex_color,
    });
  };

  const disabled = status === 'scrapped';

  return (
    <tr>
      <GraspInfo
        name={name}
        hexColor={hex_color}
        toggleExpand={toggleExpand}
        uuid={physical_grasp_uuid}
        graspId={id}
        organizationId={organization_id}
        disabled={disabled}
      />

      <CreatedAt date={created_at} />

      <UpdatedAt date={updated_at} />

      <Organization
        organization={organization}
        redirect={redirectToOrganization}
        href={linkToOrganization}
      />

      <Endpoint
        endpoint={endpoint}
        redirect={redirectToEndpoint}
        href={linkToEndpoint}
      />

      <CreatedBy user={created_by} />

      <OwnedBy user={owner} />

      <Status
        status={status}
        statusOptions={[]}
        graspId={id}
        organizationId={organization_id}
        disabled={disabled}
      />

      <SerialNumber
        disabled={disabled}
        serialNumber={serial_number}
        graspId={id}
        organizationId={organization_id}
      />

      <Numbertag
        disabled={disabled}
        numbertag={numbertag}
        graspId={id}
        organizationId={organization_id}
      />

      <Flashdate
        disabled={disabled}
        date={flashdate}
        graspId={id}
        organizationId={organization_id}
      />

      <EventLog
        disabled={!organization || disabled}
        redirect={redirectToEventLog}
        href={linkToEventLog}
      />

      <ActionButton
        disabled={disabled}
        name={name}
        serialNumber={serial_number}
        status={status}
        graspId={id}
        owner={owner}
        endpoint={endpoint}
        organization={{ id: organization_id }}
        hasOwner={is_linked_to_user}
        isAssignedToOrganization={is_assigned_to_organization}
        isAssignedToEndpoint={is_assigned_to_endpoint}
      />
    </tr>
  );
};

const mapDispatchToProps = {
  redirectTo: push,
};

export default connect(null, mapDispatchToProps)(InventoryRow);
