import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import AddGraspModal from './AddGraspModal';

const GraspsCard = ({ graspsCount = 0, organizationId }) => {
  const [isAddGraspModalOpen, updateIsAddGraspModalOpen] = useState(false);

  const toggleModal = () => updateIsAddGraspModalOpen(!isAddGraspModalOpen);

  return (
    <>
      <Card className="organization-card organization-card--count">
        <CardBody>
          <div className="d-flex justify-content-between">
            <CardTitle className="mb-0">
              <FormattedMessage id="Organization.cards.graspsCount" />
            </CardTitle>
            <Button size="sm" color="primary" onClick={toggleModal}>
              <FormattedMessage id="AddGraspModal.add" />
            </Button>
          </div>

          <h2 className="text-center">{graspsCount}</h2>

          <Button
            className="p-0 m-0"
            color="link"
            tag={Link}
            to={`/organization/${organizationId}/grasps`}
          >
            <FormattedMessage id="Organization.cards.manageGrasps" />
          </Button>
        </CardBody>
      </Card>

      {isAddGraspModalOpen && (
        <AddGraspModal isOpen={isAddGraspModalOpen} toggle={toggleModal} />
      )}
    </>
  );
};

export default GraspsCard;
