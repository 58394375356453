import React from 'react';
import { Card, CardBody } from 'reactstrap';

const CardRow = ({ action, id, name }) => {
  const handleClick = () => action(id, '')();

  return (
    <Card
      className="my-2 pointer"
      key={`endpoint-${id}`}
      onClick={handleClick}
    >
      <CardBody>
        {name}
      </CardBody>
    </Card>
  );
};

export default CardRow;
