import React, { useState } from 'react';

// Image fallback
import fallbackImage from '../assets/img/logo.svg';

/**
 * Renders images, fallback to grasp logo
 */
const Image = ({
  alt,
  src,
  ...rest
}) => {
  const [imageSrc, updateImageSrc] = useState(src);
  const handleError = () => updateImageSrc(fallbackImage);

  return (
    <img
      src={imageSrc}
      onError={handleError}
      alt={alt}
      {...rest}
    />
  );
};

export default Image;
