import request from '../request';

// HELPER:
const getQueryParams = ({
  page,
  pageSize,
  sortBy,
  resource_id
  // eslint-disable-next-line
}) =>
  `?page=${page}${pageSize ? `&page_size=${pageSize}` : ''}${
    sortBy ? `&sort=${sortBy}` : ''
  }${resource_id ? `&resource_id=${resource_id}` : ''}`;

export const getEventLogs = (reduxParams, resourceId) =>
  request({
    url: `/event_logs${getQueryParams({
      ...reduxParams,
      resource_id: resourceId
    })}`,
    method: 'GET'
  });

export const getGraspFromOrganization = (organizationId, graspId) =>
  request({
    url: `/organizations/${organizationId}/grasps/${graspId}`,
    method: 'GET'
  });
