import React from 'react';
import GraspLogo from "../../modules/membership/login/components/GraspLogo";
import {Row, Container, Col} from "reactstrap";

const MembershipLayout = ({ children }) => (
  <div className="bg-gradient sizing-fill d-flex align-items-center">
    <Container>
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={10} md={{ offset: 1, size: 3 }} lg={2} xl={2} className="mb-4 mb-md-0 d-flex justify-content-center align-items-center">
          <GraspLogo className="text-center" />
        </Col>
        <Col xs={11} sm={10} md={{ offset: 1, size: 6 }} lg={4} xl={4}>
          {React.Children.only(children)}
        </Col>
      </Row>
    </Container>
  </div>
);

export default MembershipLayout;
