import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

// COMPONENTS:
import Image from '../../../components/Image';

/**
 * Organization item (Card) rendered in Organization list
 */
const OrganizationItem = ({
  organization: {
    total_endpoints_count,
    total_grasps_count,
    logo_url,
    name,
    id,
  },
}) => (
  <Col xs={12} md={6} lg={4}>
    <Card
      className="my-2 text-decoration-none text-dark"
      tag={Link}
      to={`/organization/${id}`}
    >
      <CardBody className="d-flex align-items-center">
        <div
          className="text-center mr-4"
          style={{ width: '35%' }}
        >
          <Image
            src={logo_url}
            alt={`${name} logo`}
            style={{ height: 60 }}
          />
        </div>

        <div style={{ width: '60%' }}>
          <h6 className="my-1">{name}</h6>

          <small className="d-block">
            <FormattedMessage
              id="Dashboard.endpointsCount"
              values={{ count: total_endpoints_count }}
            />
          </small>

          <small className="d-block">
            <FormattedMessage
              id="Dashboard.devicesCount"
              values={{ count: total_grasps_count }}
            />
          </small>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default OrganizationItem;
