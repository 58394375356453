const DEFAULT_PAGE_SIZE = 10;

export const initialState = {
  list: [],
  filterBy: 'All',
  sortBy: null,
  pageSize: DEFAULT_PAGE_SIZE,
  pagination: {},
  isLoading: false,
  isSuccess: false,
  error: null,
  meta: null,
};

/**
 * Higher Order Reducer
 * @param name - dynamic actions name
 * @param reducerState - initial reducer state
 */
const withInfinitePaginationReducers = (name, reducerState = initialState) => (
  state = reducerState,
  action,
) => {
  switch (action.type) {
    case `FETCH_${name}_STARTED`:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };

    case `FETCH_${name}_SUCCESS`:
      return {
        ...state,
        list: [...state.list, ...action.list],
        pagination: action.pagination,
        isLoading: false,
        isSuccess: true,
        meta: action.meta,
      };

    case `FETCH_${name}_ERROR`:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case `CHANGE_${name}_PAGE`:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          current_page: action.page,
        },
      };

    case `CLEAR_${name}_STORE`:
      return reducerState;

    default:
      return state;
  }
};

export default withInfinitePaginationReducers;
