import React from 'react';
import { Container } from 'reactstrap';

// COMPONENTS:
import InventorySection from './components/InventorySection';
import OrganizationSection from './components/OrganizationsSection';

const AdminPanel = () => (
  <Container className="pb-5 mb-5">
    <InventorySection />
    <OrganizationSection />
  </Container>
);

export default AdminPanel;
