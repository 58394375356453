import {
  getOrganizationDetails,
  createEndpoint,
  addGraspToOrganization,
  updateOrganization,
} from '../../../api/organizations';
import fetchAsyncWithDataReducers from '../../../helpers/HOR/asyncActionWithData/reducer';
import { fetchAsyncActionsWithData } from '../../../helpers/HOR/asyncActionWithData/actions';
import { asyncActions } from '../../../helpers/HOR/asyncAction/actions';
import asyncReducers from '../../../helpers/HOR/asyncAction/reducer';

// HELPERS:
const MODULE_NAME = 'ORGANIZATION';
const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
const ADD_ENDPOINT = 'ADD_ENDPOINT';
const ADD_GRASP = 'ADD_GRASP';

// REDUCER:
export const organizationDashboardReducer = fetchAsyncWithDataReducers(
  MODULE_NAME
);
export const addEndpointReducer = asyncReducers(ADD_ENDPOINT);
export const addGraspReducer = asyncReducers(ADD_GRASP);
export const editOrganizationReducer = asyncReducers(EDIT_ORGANIZATION);

// ACTIONS:
export const fetchOrganizationAction = fetchAsyncActionsWithData(
  MODULE_NAME,
  getOrganizationDetails
);

const editOrganizationAsyncActionParameters = {
  name: EDIT_ORGANIZATION,
  APICall: updateOrganization,
  callback: (data, organizationId) => fetchOrganizationAction(organizationId),
  onSuccessNotification: { code: 'organization_updated' },
};

export const editOrganizationAction = asyncActions(
  editOrganizationAsyncActionParameters
);

const addEndpointAsyncActionParameters = {
  name: ADD_ENDPOINT,
  APICall: createEndpoint,
  callback: (data, organizationId) => fetchOrganizationAction(organizationId),
  onSuccessNotification: { code: 'endpoint_added' },
};

export const addEndpointAction = asyncActions(addEndpointAsyncActionParameters);

const addGraspAsyncActionParameters = {
  name: ADD_GRASP,
  APICall: addGraspToOrganization,
  onSuccessNotification: { code: 'grasp_added' },
};

export const addGraspAction = asyncActions(addGraspAsyncActionParameters);
