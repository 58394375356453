import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';

const UsersCard = ({ usersCount = '0', organizationId }) => (
  <Card className="organization-card organization-card--count">
    <CardBody>
      <div className="d-flex justify-content-between">
        <CardTitle>
          <FormattedMessage id="Organization.cards.usersCount" />
        </CardTitle>
      </div>

      <h2 className="text-center">{usersCount}</h2>

      <Button
        className="p-0 m-0"
        color="link"
        tag={Link}
        to={`/organization/${organizationId}/users`}
      >
        <FormattedMessage id="Organization.cards.viewAll" />
      </Button>
    </CardBody>
  </Card>
);

export default UsersCard;
