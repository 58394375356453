import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

const InventorySection = () => (
  <Row className="mb-5">
    <Col className="d-block d-md-flex align-items-center justify-content-between">
      <h2>
        <FormattedMessage id="Dashboard.inventory" />
      </h2>

      <Button
        tag={Link}
        to="/grasp-inventory"
        color="primary"
      >
        <FontAwesomeIcon icon={faEye} className="mr-2" />
        <FormattedMessage id="Dashboard.viewInventory" />
      </Button>
    </Col>
  </Row>
);

export default InventorySection;
