import React, { useState, useEffect, createRef } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';

// ACTIONS:
import { addGraspAction } from '../reducer';
import { displayNotificationAction } from '../../../notifications/actions';

// COMPONENTS:
import InputCustom from '../../../../components/forms/InputCustom';
import LoadingButton from '../../../../components/LoadingButton';

// HELPERS:
import { graspAvailableColors } from '../../../../helpers/graspAvailableColors';
import { changeInventoryQueryAction } from '../../../graspInventory/reducer';

/**
 * Modal with form for creating new endpoint
 */
const AddGraspModal = ({
  intl,
  isOpen,
  toggle,
  isLoading,
  isSuccess,
  error,
  match,
  addGraspToPool,
  displayNotification,
  changeSearchQuery,
  redirect,
}) => {
  const initialValues = { uuid: '', serialNumber: '', color: null };
  const [formValues, updateFormValue] = useState(initialValues);
  const uuidRef = createRef();

  useEffect(() => uuidRef.current.focus(), []);

  useEffect(() => {
    return () => updateFormValue(initialValues);
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    updateFormValue({ ...formValues, [name]: value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const model = {
      serial_number: formValues.serialNumber,
      physical_grasp_uuid: formValues.uuid,
      hex_color: formValues.color,
    };
    await addGraspToPool(match.params.id, model);
  };

  const submitCallback = async () => {
    await changeSearchQuery(formValues.uuid);
    await redirect('/grasp-inventory');
  };

  useEffect(() => {
    if (error && error.data && error.data.meta) {
      error.data.meta.errors.forEach((err) => {
        switch (err.code) {
          case 'serial_number_already_exists_error': {
            displayNotification({ code: 'serialnumber_already_exist' });
            return updateFormValue({ ...formValues, serialNumber: '' });
          }

          case 'grasp_already_exists_error': {
            displayNotification({ code: 'grasp_already_exists_error' });
            return updateFormValue({ ...formValues, uuid: '' });
          }

          default:
            return null;
        }
      });
    }
  }, [error]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <FormattedMessage id="AddGraspModal.header" />
        <div style={{ fontSize: '0.8rem' }} className="text-muted">
          <FormattedMessage id="AddGraspModal.subheader" />
        </div>
      </ModalHeader>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalBody>
          <InputCustom
            className="w-100"
            name="uuid"
            innerRef={uuidRef}
            id="add-unassigned-grasp-uuid"
            onChange={handleChange}
            label={<FormattedMessage id="AddGraspModal.UUID" />}
            value={formValues.uuid}
            required
            autoComplete="false"
          />
          <InputCustom
            className="w-100"
            name="serialNumber"
            id="add-unassigned-grasp-serial-number"
            onChange={handleChange}
            label={<FormattedMessage id="AddGraspModal.serialNumber" />}
            value={formValues.serialNumber}
            autoComplete="false"
          />
          <FormGroup>
            <Label htmlFor="add-unassigned-grasp-color">
              <FormattedMessage id="AddGraspModal.pickColor" />
            </Label>
            <InputCustom
              name="color"
              onChange={handleChange}
              value={formValues.color}
              type="select"
              options={graspAvailableColors.map((option) => (
                <option key={option} value={option}>
                  {intl.formatMessage({ id: `AddGraspModal.colors.${option}` })}
                </option>
              ))}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="light" onClick={toggle}>
            <FormattedMessage id="AddGraspModal.close" />
          </Button>

          <LoadingButton
            color="primary"
            type="submit"
            callback={submitCallback}
            isLoading={isLoading}
            isSucceed={isSuccess}
            isFailed={!!error}
          >
            <FormattedMessage id="AddGraspModal.add" />
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  organization: {
    creatingGrasp: { isLoading, isSuccess, error },
  },
}) => ({
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  addGraspToPool: addGraspAction,
  displayNotification: displayNotificationAction,
  changeSearchQuery: changeInventoryQueryAction,
  redirect: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(AddGraspModal)));
