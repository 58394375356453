import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// HELPERS:
import { isAuthenticated } from '../../../helpers/authentication';

// ACTIONS:
import { authorizeLogin } from './actions';

// COMPONENTS:
import LoginForm from './components/LoginForm';
import MembershipHeader from '../MembershipHeader';

class Login extends Component {
  state = {
    username: '',
    password: '',
  };

  componentDidMount() {
    if (isAuthenticated()) this.props.redirect('/');
  }

  handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.authorizeLogin(this.state.username, this.state.password);
  };

  render() {
    const {
      isLoading,
      isSucceed,
      loginFailed,
    } = this.props;

    return (
      <>
        <MembershipHeader id="LoginForm.login" />
        <LoginForm
          loginFailed={loginFailed}
          values={this.state}
          isLoading={isLoading}
          isSucceed={isSucceed}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
        />
      </>
    );
  }
}

const mapStateToProps = ({ membership: { login } }) => ({
  isLoading: login.isLoggingIn,
  isSucceed: login.loginSuccess,
  loginFailed: login.loginFailed,
});

const mapDispatchToProps = {
  authorizeLogin,
  redirect: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
