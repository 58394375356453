import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

const FilterButton = ({
  currentFilter,
  filterType,
  changeFilter,
}) => {
  const isActive = currentFilter === filterType;

  return (
    <Button
      className={`${isActive && 'font-weight-bold'} text-decoration-none text-nowrap pl-0 ml-0`}
      color="link"
      active={isActive}
      onClick={() => changeFilter(filterType)}
    >
      <FormattedMessage id={`Filters.${filterType}`} />
    </Button>
  );
};

export default FilterButton;
