import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

// ACTIONS:
import { fetchOrganizationAction, addEndpointAction } from './reducer';

// COMPONENTS:
import EndpointCard from './components/EndpointCard';
import GraspsCard from './components/GraspsCard';
import UsersCard from './components/UsersCard';
import Header from './components/Header';
import GraspsChartCard from './components/GraspsChartCard';
import LoadingSpinner from '../../../components/LoadingSpinner';

const Organization = ({
  details,
  meta,
  isSuccess,
  isLoading,
  error,
  addEndpoint,
}) => {
  switch (true) {
    case isLoading:
      return <LoadingSpinner className="pt-3 text-center" size="2x" />;

    case error:
      return <div>failed</div>;

    case isSuccess: {
      const { id, name, logo_url } = details;
      const {
        endpoints_count,
        grasps_count,
        unassigned_grasps_count,
        assigned_grasps_count,
        users_count,
      } = meta.organization_dashboard || {};

      return (
        <Container className="mb-5">
          <Header name={name} logoUrl={logo_url} />

          <Row>
            <Col xs={6} lg={4}>
              <UsersCard usersCount={users_count} organizationId={id} />
            </Col>

            <Col xs={6} lg={4}>
              <EndpointCard
                endpointsCount={endpoints_count}
                organizationId={id}
                addEndpoint={addEndpoint}
              />
            </Col>

            <Col xs={12} lg={4}>
              <GraspsCard graspsCount={grasps_count} organizationId={id} />
            </Col>

            <Col xs={12} lg={8}>
              <GraspsChartCard
                unassignedGrasps={unassigned_grasps_count}
                assignedGrasps={assigned_grasps_count}
                organizationId={id}
              />
            </Col>
          </Row>
        </Container>
      );
    }

    default:
      return null;
  }
};

const mapStateToProps = ({
  organization: {
    details: { isLoading, isSuccess, error, data, meta },
  },
}) => ({
  details: data,
  meta,
  isLoading,
  isSuccess,
  error,
});

const mapDispatchToProps = {
  fetchOrganizationDetails: fetchOrganizationAction,
  addEndpoint: addEndpointAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
