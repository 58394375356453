import { combineReducers } from 'redux';
import { getOrganizations, addOrganization } from '../../api/organizations';
import withPaginationReducers, { initialState } from '../../helpers/HOR/withPagination/reducer';
import {
  withPaginationActions,
  changePageAction,
} from '../../helpers/HOR/withPagination/actions';
import { asyncActions } from '../../helpers/HOR/asyncAction/actions';
import asyncReducers from '../../helpers/HOR/asyncAction/reducer';

// HELPERS:
const MODULE_NAME = 'ORGANIZATIONS';
const organizationsStore = getState => getState().dashboard.organizations;
const organizationsInitialState = { ...initialState, pageSize: 12 };

const ADD_ORGANIZATION = 'ADD_ORGANIZATION';

// REDUCER:
export const organizationsReducer = withPaginationReducers(MODULE_NAME, organizationsInitialState);
export const addOrganizationReducer = asyncReducers(ADD_ORGANIZATION);

// ACTIONS:
export const fetchOrganizationsAction = withPaginationActions(
  MODULE_NAME,
  getOrganizations,
  organizationsStore,
);

const addOrganizationAsyncActionParameters = {
  name: ADD_ORGANIZATION,
  APICall: addOrganization,
  callback: fetchOrganizationsAction,
  onSuccessNotification: { code: 'organization_added' },
};

export const addOrganizationAction = asyncActions(addOrganizationAsyncActionParameters);

export const changeOrganizationsPageAction = changePageAction(MODULE_NAME, fetchOrganizationsAction);

// COMBINED REDUCERS
export default combineReducers({
  organizations: organizationsReducer,
  addOrganization: addOrganizationReducer,
});
