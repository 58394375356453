import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';

import { PieChart, Pie, Cell } from 'recharts';
import { FormattedMessage } from 'react-intl';

const GraspsChartCard = ({
  unassignedGrasps = 0,
  assignedGrasps = 0,
  organizationId,
}) => {
  const chartData = [
    {
      name: 'Unassigned',
      value: unassignedGrasps,
      color: '#adb5bd',
    },
    {
      name: 'Assigned',
      value: assignedGrasps,
      color: '#537ae4',
    },
  ];

  return (
    <Card className="organization-card">
      <CardBody className="d-flex pt-0">
        <div style={{ width: '40%' }}>
          <PieChart width={160} height={160}>
            <Pie data={chartData} dataKey="value" nameKey="name">
              {chartData.map(({ color, name }) => (
                <Cell key={`cell-${name}`} fill={color} />
              ))}
            </Pie>
          </PieChart>
        </div>

        <div className="pt-3" style={{ width: '60%' }}>
          <CardTitle>
            <FormattedMessage id="Organization.cards.grasps" />
          </CardTitle>

          <p className="mb-0 d-flex align-items-center">
            <span
              className="pie-chart-legend"
              style={{ backgroundColor: chartData[0].color }}
            />
            <FormattedMessage
              id="Organization.cards.unassignedGrasps"
              values={{ count: unassignedGrasps }}
            />
          </p>

          <p className="d-flex align-items-center">
            <span
              className="pie-chart-legend"
              style={{ backgroundColor: chartData[1].color }}
            />
            <FormattedMessage
              id="Organization.cards.assignedGrasps"
              values={{ count: assignedGrasps }}
            />
          </p>

          <Button
            className="p-0 m-0"
            color="link"
            tag={Link}
            to={`/organization/${organizationId}/grasps`}
          >
            <FormattedMessage id="Organization.cards.manageGrasps" />
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default GraspsChartCard;
