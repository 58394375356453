import React from 'react';

// COMPONENTS:
import EndpointsItem from './EndpointsItem';

const OrganizationsList = ({ list }) => list && list.map(endpoint => (
  <EndpointsItem
    key={endpoint.id}
    endpoint={endpoint}
  />
));

export default OrganizationsList;
