import React from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Grasp from '../../../assets/img/grasp.png';

const EventLogGrasp = ({ grasp }) => (
  <Row className="mb-4">
    <Col className="d-flex align-items-center">
      <div className="mr-2">
        <h1 className="mb-0">
          <FormattedMessage id="EventLog.events" />
        </h1>
        <div>{grasp && grasp.name}</div>
      </div>
      <div
        style={{
          width: 116,
          height: 82,
          backgroundImage: `url(${Grasp})`,
          backgroundColor: `${grasp && grasp.hex_color}`,
          backgroundBlendMode: 'multiply',
          WebkitMaskImage: `url(${Grasp})`
        }}
      />
    </Col>
  </Row>
);

export default EventLogGrasp;
