import React from 'react';

// COMPONENTS:
import Date from '../tableComponents/Date';

const UpdatedAt = ({ date }) => (
  <td className="text-center">
    <Date date={date} />
  </td>
);

export default UpdatedAt;
