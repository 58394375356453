import React from 'react';
import { Form } from "reactstrap";
import LoadingButton from "../../../../components/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ConfirmEmailForm = ({ isLoading, isSuccess, resetFailed, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <div className="text-center">
      <LoadingButton
        isLoading={isLoading}
        isSucceed={isSuccess}
        isFailed={resetFailed}
        value="ConfirmEmail.buttonText"
        type="submit"
        className="btn btn-outline-light btn-block btn-login"
      >
        <FontAwesomeIcon icon={faCheck} className="ml-1" />
      </LoadingButton>
    </div>
    <div className="mt-2 text-center">
      <Link to="/login" className="text-white">
        <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
        <FormattedMessage id="ResetPassword.back" />
      </Link>
    </div>
  </Form>
);

export default ConfirmEmailForm;
