import {
  getUsersFromOrganization,
  inviteUserToOrganization,
} from '../../../api/organizations';
import withPaginationReducer from '../../../helpers/HOR/withPagination/reducer';
import {
  withPaginationActions,
  changePageAction,
  clearReducerStoreAction,
} from '../../../helpers/HOR/withPagination/actions';
import { asyncActions } from '../../../helpers/HOR/asyncAction/actions';
import asyncReducers from '../../../helpers/HOR/asyncAction/reducer';

// HELPERS:
const USERS_FROM_ORGANIZATION = 'USERS_FROM_ORGANIZATION';
const INVITE_USER = 'INVITE_USER';
const organizationUsersStore = (getState) => getState().organization.users;

// REDUCER:
export const organizationUsersReducer = withPaginationReducer(
  USERS_FROM_ORGANIZATION
);

// ACTIONS:
export const fetchUsersFromOrganizationAction = withPaginationActions(
  USERS_FROM_ORGANIZATION,
  getUsersFromOrganization,
  organizationUsersStore
);

export const changeOrganizationUsersPageAction = changePageAction(
  USERS_FROM_ORGANIZATION,
  fetchUsersFromOrganizationAction
);

export const clearOrganizationUsersStoreAction = clearReducerStoreAction(
  USERS_FROM_ORGANIZATION
);

const addGraspInviteUserParameters = {
  name: INVITE_USER,
  APICall: inviteUserToOrganization,
  onSuccessNotification: { code: 'user_added' },
};

export const inviteUser = asyncActions(addGraspInviteUserParameters);

export const inviteUserReducer = asyncReducers(INVITE_USER);
