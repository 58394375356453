import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// REDUCERS:
import membershipReducer from '../modules/membership/reducer';
import graspInventoryReducer from '../modules/graspInventory/reducer';
import dashboardReducer from '../modules/dashboard/reducer';
import organizationReducer from '../modules/organization/reducer';
import notificationsReducer from '../modules/notifications/reducer';
import { eventLogsReducer } from '../modules/eventLogs/reducer';

export default combineReducers({
  membership: membershipReducer,
  dashboard: dashboardReducer,
  organization: organizationReducer,
  inventory: graspInventoryReducer,
  router: routerReducer,
  notifications: notificationsReducer,
  eventLogs: eventLogsReducer
});
