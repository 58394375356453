import React, { useState } from 'react';
import { connect } from "react-redux";

// COMPONENTS:
import PasswordResetForm from "./components/PasswordResetForm";
import MembershipHeader from "../MembershipHeader";
import { resetPasswordAction } from "../password-reset/actions";

const PasswordReset = ({ isLoading, isSuccess, resetFailed, resetPassword }) => {
  const [values, setValues] = useState({ email: ''});

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setValues({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(values.email);
  };

  return (
    <>
      <MembershipHeader id="ResetPassword.header" />
      <PasswordResetForm
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isSuccess={isSuccess}
        resetFailed={resetFailed}
      />
    </>
  );
};

const mapStateToProps = ({ membership: { passwordReset } }) => ({
  isLoading: passwordReset.isResetting,
  isSucceed: passwordReset.resetSuccess,
  resetFailed: passwordReset.resetFailed,
});

const mapDispatchToProps = {
  resetPassword: resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
