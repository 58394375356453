import { push } from 'react-router-redux';
import { signIn } from '../../../api/membership';

// HELPERS:
import { setTokens } from '../../../helpers/authentication';

export const isLoggingIn = () => ({
  type: 'IS_LOGGING_IN',
});

export const loginSuccess = () => ({
  type: 'LOGIN_SUCCESS',
});

export const loginFailed = (errorMessage) => ({
  type: 'LOGIN_FAILED',
  errorMessage,
});

export const authorizeLogin = (userEmail, password) => (dispatch) => {
  dispatch(isLoggingIn());
  signIn({ email: userEmail, password })
    .then(({ data: { data } }) => {
      if (data.token) {
        const { id, email, first_name, last_name } = data.user;

        setTokens(data.token, data.valid_until);
        localStorage.setItem('user', id);
        localStorage.setItem('userEmail', email);
        localStorage.setItem(
          'username',
          `${first_name || ''} ${last_name || ''}`
        );

        dispatch(loginSuccess());
        dispatch(push('/'));
      } else {
        dispatch(loginFailed('Something went wrong'));
      }
    })
    .catch((error) => {
      if (error && error.data && error.data.meta.errors[0]) {
        if (
          error.data.meta.errors[0].code === 'user_email_not_confirmed_error'
        ) {
          sessionStorage.setItem('email', userEmail);
          dispatch({
            type: 'DISPLAY_NOTIFICATION',
            notification: { code: 'user_email_not_confirmed_error' },
          });
          return dispatch(push('/resend-email'));
        }
        dispatch(loginFailed(''));
        error.data.meta.errors.map((singleError) =>
          dispatch({
            type: 'DISPLAY_NOTIFICATION',
            notification: { code: singleError.code },
          })
        );
      } else {
        dispatch(loginFailed(''));
        dispatch({
          type: 'DISPLAY_NOTIFICATION',
          notification: { code: 'login_failed' },
        });
      }
    });
};
