import { getEndpointGrasps, unassignGraspFromEndpoint } from '../../../../../api/organizations';
import withPaginationReducer, { initialState } from '../../../../../helpers/HOR/withPagination/reducer';
import {
  changePageAction,
  withPaginationActions,
  clearReducerStoreAction,
} from '../../../../../helpers/HOR/withPagination/actions';
import { asyncActions } from '../../../../../helpers/HOR/asyncAction/actions';

// HELPERS:
const MODULE_NAME = 'UNLINKED_GRASPS';
const UNASSIGN_FROM_ENDPOINT = 'UNASSIGN_UNLINKED_GRASPS_FROM_ENDPOINT';
const unlinkedGraspsInitialState = { ...initialState, filterBy: 'OnlyUnlinked', pageSize: 6 };
const unlinkedGraspsStore = getState => getState().organization.endpoint.unlinkedGrasps;

// REDUCER:
export const unlinkedGraspsReducer = withPaginationReducer(MODULE_NAME, unlinkedGraspsInitialState);

// ACTIONS:
export const fetchUnlinkedGraspsAction = withPaginationActions(
  MODULE_NAME,
  getEndpointGrasps,
  unlinkedGraspsStore,
);

const unassignGraspAsyncActionParameters = {
  name: UNASSIGN_FROM_ENDPOINT,
  APICall: unassignGraspFromEndpoint,
  callback: (data, organizationId, endpointId) => fetchUnlinkedGraspsAction(organizationId, endpointId),
  onSuccessNotification: { code: 'grasp_unassigned' },
};

export const unassignGraspFromEndpointAction = asyncActions(unassignGraspAsyncActionParameters);
export const changeUnlinkedGraspsPageAction = changePageAction(MODULE_NAME, fetchUnlinkedGraspsAction);
export const clearUnlinkedGraspsStoreAction = clearReducerStoreAction(MODULE_NAME);
