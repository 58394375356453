const withParams = (APICall, params) => () => APICall(...params);

/**
 * Higher Order Actions function that create async calls to API with redux-thunk
 * @param name - dynamic actions name
 * @param APICall - function that call API
 * @param callback - function that can be called after APICall
 */
export const fetchAsyncActionsWithData = (name, APICall, callback) => (...params) => {
  const thunkAction = withParams(APICall, params);

  return (dispatch) => {
    dispatch({ type: `FETCH_${name}_STARTED` });

    return dispatch(thunkAction)
      .then(({ data: { data, meta } }) => dispatch({ type: `FETCH_${name}_SUCCESS`, data, meta }))
      .then(data => callback && dispatch(callback(data, ...params)))
      .catch((error) => {
        dispatch({ type: `FETCH_${name}_ERROR`, error });
      });
  };
};

export const clearReducerStoreAction = name => () => dispatch => dispatch({ type: `CLEAR_${name}_STORE` });
